@import 'src/styles/vars';

.mobileBurger {
  height: 40px;
  position: relative;
  width: 52px;
  &::before,
  &::after {
    background-color: #000000;
    content: '';
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transition: transform $transition_timing;
    width: 24px;
  }

  &::before {
    top: 15px;
  }
  &::after {
    bottom: 15px;
  }
}
