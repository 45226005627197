@import 'src/styles/colors';

.formControl {
  margin: 0;
  .checkboxRoot {
    margin: 0;
    padding: 5px;
    &.checkboxDisabled svg path {
      fill: $gray;
    }
  }
  .colorSecondary {
    background-color: transparent !important;
  }
  .label {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    user-select: none;
    &.controlDisabled {
      color: $gray;
    }
  }
}
