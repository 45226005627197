@import 'src/styles/colors';
@import 'src/styles/vars';

.actionsCellContainer {
  align-items: center;
  background-color: $white;
  column-gap: 14px;
  display: flex;
  height: 100%;
  padding-left: 10px;
  svg {
    cursor: pointer;
    height: 14px;
    width: 14px;
    &:hover {
      path {
        fill: $WCMAccent;
      }
    }
  }
  .viewRequest {
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: $WCMAccent;
    }
  }
}
