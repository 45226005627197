@import 'src/styles/colors';

.popperTooltip {
  .tooltip {
    align-items: center;
    background-color: $grayDarker;
    border-radius: 0;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 11px;
    justify-content: center;
    line-height: 13px;
    max-width: 216px;
    padding: 8px;
    text-align: center;
    &.dark {
      filter: drop-shadow(0px 0px 1px $white);
    }
    &.error {
      background-color: $danger;
      .tooltipArrow {
        color: $danger;
      }
    }
    &.textAlignleft {
      text-align: left;
    }
    &.textAlignright {
      text-align: right;
    }
    .tooltipArrow {
      color: $grayDarker;
    }
  }
}
