.primaryButton {
  background-color: #d9a81b;
  border: none;
  color: white;
  cursor: pointer;
  height: 35px;
  min-width: 130px;

  &:focus,
  &:hover {
    background-color: #ba9803;
  }
}
