@import 'src/styles/colors';
@import 'src/styles/vars';

.footer {
  border-top: 1px solid $gray;
  column-gap: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 986px;
  padding: 30px 0;
  row-gap: 10px;
  width: calc(100% - 100px);
  & > a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $WCMAccent;
    }
  }
  & > span {
    margin-left: auto;
  }
  .cookiesSettings {
    cursor: pointer;
  }
}
@media (max-width: $breakpoint_tablet - 1) {
  .copyright {
    margin-top: 14px;
    width: 100%;
  }
}

@media (max-width: $breakpoint_mobile - 1) {
  .footer {
    width: calc(100% - 50px);
  }
}
