@import 'src/styles/colors';
@import 'src/styles/vars';

.songDetails {
  :global {
    .MuiSnackbar-root {
      position: fixed !important;
    }
    .ag-root-wrapper {
      min-height: 170px !important;
    }
  }
  &.songDetailsOpenSidebar {
    :global {
      .MuiSnackbar-root {
        left: calc((100% - #{$sidebarWidth}) / 2);
      }
    }
  }
  .arrowBackIcon {
    cursor: pointer;
    margin-bottom: 28px;
    margin-left: 4px;
  }
  .arrowDownIcon {
    path {
      fill: $white;
    }
  }
  .actionsCellContainer {
    align-items: center;
    column-gap: 20px;
    display: flex;
    height: 100%;
    position: absolute;
    right: 15px;
    svg {
      cursor: pointer;
      height: 16px;
      width: 16px;
      &:hover {
        path {
          fill: $WCMAccent;
        }
      }
    }
  }
  .cellWithEditTooltip {
    align-items: center;
    display: flex;
    z-index: $aboveActionsCellZIndex;
    span {
      height: 50px;
      line-height: 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    & > div {
      max-width: 100%;
    }
  }
  .fullWidthCell {
    :global {
      .ag-react-container {
        width: 100%;
      }
    }
  }
  .sectionTitle {
    color: $grayDarker;
    display: block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .sectionContainer {
    margin-top: 38px;
  }
  .statusPlusButtons {
    column-gap: 36px;
    display: flex;
    flex-direction: row;
  }
  .songHeader {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    .writersContainer {
      flex-wrap: wrap;
    }
    .songTitle,
    .writers,
    .writersSeparator {
      display: block;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
    }
    .songTitle {
      color: $grayDarker;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 6px;
      & ~ svg {
        top: -3px;
      }
    }
    .writers,
    .writersSeparator {
      color: $gray;
      font-size: 16px;
      line-height: 20px;
    }
    .writers {
      text-decoration: none;
    }
    .writersSeparator {
      margin: 0 4px;
    }
  }
  .songDetailsTagsContainer {
    margin-top: 20px;
    .songDetailsTagsList {
      align-items: center;
      column-gap: 12px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      row-gap: 6px;
      svg {
        cursor: pointer;
      }
      .songDetailsTag {
        align-items: center;
        background-color: $WCMAccent;
        border-radius: 50px;
        color: #000000;
        display: flex;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 10px;
        line-height: 20px;
        padding: 0 8px 0 14px;
        text-decoration: none;
        .aiTagIcon {
          margin-right: 6px;
        }
        .aiTagIcon,
        .deleteIcon {
          height: 100%;
          width: 12px;
          path {
            fill: #000000;
          }
        }
        .deleteIcon {
          margin-left: 4px;
          padding: 0 2px;
        }
        &.withoutDeleteIcon {
          padding-right: 14px;
        }
      }
    }
  }
  .songHeaderCols {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 65px;
    margin-top: 38px;
    .labelViewCol {
      overflow: hidden;
      padding-left: 10px;
    }
  }
  .grayTitle {
    color: $gray;
    display: block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
  .mb4 {
    margin-bottom: 4px;
  }
  .infoText {
    color: $grayDarker;
    display: unset;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 14px;
    line-height: 18px;
  }
  .switchBtnRoot {
    overflow: visible;
    padding-left: 0;
  }
  .labelViewText {
    margin-left: 6px;
    margin-right: 2px;
  }
  .statusCardContainer {
    align-items: center;
    display: flex;
    :global {
      .ag-react-container {
        max-width: 100%;
      }
    }
  }
  .statusCard {
    align-items: center;
    background-color: $gray;
    border-radius: 12px;
    color: $white;
    display: flex;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 11px;
    height: 18px;
    padding: 2px 10px;
  }
  .notesSection {
    padding-bottom: 10px;
    width: fit-content;
    .notesEditIcon {
      height: unset;
      left: unset;
      right: 63px;
      top: 20px;
    }
    .editTooltipContainer {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
      &:hover .note .noteBtnGroup {
        display: flex;
      }
    }
    .note {
      background-color: $wipAreaBck;
      border-radius: 6px;
      padding: 20px 20px 22px;
      position: relative;
      width: 534px;
      .noteText {
        margin: 12px 0;
        word-break: break-word;
      }
      .noteAuthor {
        padding-right: 50px;
      }
      & > span {
        display: block;
      }
      .noteBtnGroup {
        align-items: center;
        column-gap: 15px;
        display: none;
        position: absolute;
        right: 20px;
        top: 20px;
        svg {
          cursor: pointer;
          &:hover path {
            fill: $WCMAccent;
          }
        }
      }
      &:hover {
        .noteBtnGroup {
          display: flex;
        }
      }
    }
  }
  .addNoteBtn {
    margin-top: 30px;
    svg path {
      fill: $WCMAccent;
    }
  }
}

.inputTextLabelRoot {
  margin: 0 !important;
  & > input {
    height: 35px !important;
    width: 162px !important;
    &[type='number'] {
      padding: 0 5px;
      text-align: center;
      width: 46px;
    }
  }
}

.autocompleteLabelRoot {
  margin: 0 !important;
  :global {
    .MuiAutocomplete-root {
      height: 35px;
      margin-left: 0;
      width: 164px;
    }
  }
}

.notesTextEditor {
  min-height: unset !important;
  width: 424px !important;
  :global {
    .ql-container {
      min-height: 80px !important;
      width: 424px !important;
    }
  }
}

.playCellIcon {
  svg {
    cursor: pointer;
  }
}

.playIconHeaderCell {
  svg {
    cursor: pointer;
    &.disabledPlayIcon {
      pointer-events: none;
      path {
        fill: $grayLight;
      }
    }
  }
}

.addStatus,
.addTags {
  label {
    margin: 0 0 15px 0 !important;
    width: 100%;
    input {
      height: 32px !important;
      width: 100% !important;
    }
    :global {
      .MuiTypography-root {
        color: $gray !important;
        font-family: SuisseIntl-SemiBold-M, sans-serif;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 7px;
        margin-top: 0;
      }
      .MuiAutocomplete-root {
        margin: 0;
        width: 100%;
        input {
          height: 25px !important;
        }
      }
      .MuiFormControlLabel-root {
        input {
          max-width: 220px;
        }
      }
      svg {
        bottom: 10px !important;
        top: unset !important;
      }
      .MuiSlider-root {
        margin-left: 8px;
      }
      .Mui-disabled .MuiAutocomplete-root {
        background-color: $grayLighter;
      }
    }
  }
}

.addStatus {
  max-width: 482px;
  label {
    max-width: 220px;
  }
  .addStatusContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .addStatusSwitchBtn {
    align-items: flex-start !important;
    height: 55px;
    justify-content: flex-end;
    & > span:first-of-type {
      margin-top: 4px;
    }
  }
}

.addTags {
  max-width: 482px;
  .addTagsContainer {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 300px;
    overflow: auto;
    padding-right: 10px;
  }
}

.addNotes {
  .addNotesTitle {
    color: $gray;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.writersInput {
  max-width: 271px;
  min-width: 271px;
}

@media (max-width: 1350px) {
  .songDetails {
    .songHeaderCols {
      grid-gap: 48px;
    }
  }
}

//@media (max-width: $breakpoint_tablet) {
//  .songDetails {
//    .notesSection {
//      .note {
//        width: 100%;
//      }
//    }
//  }
//}
