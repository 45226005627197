@import 'src/styles/vars';
@import 'src/styles/colors';

.formRoot {
  align-items: center !important;
  height: 30px;
  justify-content: space-between;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .label {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-right: 34px;
    transition: margin-right $transition_timing;
    white-space: nowrap;
  }
  &.cursorDefault {
    cursor: default;
  }

  @media (max-width: $breakpoint_desktop-big) {
    .label {
      margin-right: 14px;
    }
  }
}

.switchWrapper {
  // Note: this style is for tooltip position.
  display: inherit;
}
