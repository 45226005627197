@import 'src/styles/colors';
@import 'src/styles/vars';

.formControl {
  align-items: flex-start !important;
  justify-content: space-between;
  margin-left: 0 !important;
  .formLabel,
  .inputText {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
  }
  .formLabel {
    line-height: 16px;
    margin-top: 7px;
    &.disabledFormLabel {
      color: $grayLight;
    }
  }
  .root {
    margin-right: 11px;
    transition: width $transition_timing;
    width: 210px;
    .rail {
      background-color: $grayDark;
    }
    .track {
      background-color: $WCMAccent;
      &.defaultTrack {
        background-color: $grayDark;
      }
    }
    .thumb {
      background: $grayDarker;
      box-shadow: 0 0 0 5px #f0f0f0 !important;
      .valueLabel {
        color: transparent;
        transform: scale(1) translateY(44px) !important;
        .valueText {
          color: $grayDarker;
          font-family: SuisseIntl-Regular-M, sans-serif;
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }
  .disabledSlider {
    .thumb {
      background-color: $grayDark;
      height: 12px;
      margin-left: -6px;
      margin-top: -5px;
      width: 12px;
      .valueLabel {
        .valueText {
          color: $grayLight;
        }
      }
    }
    .track {
      background-color: $grayLight !important;
    }
  }

  @media (max-width: $breakpoint_desktop-big) and (min-width: $breakpoint_desktop-small + 1) {
    .root {
      width: 167px;
    }
  }

  @media (max-width: $breakpoint_desktop-small) and (min-width: $breakpoint_tablet + 1) {
    .root {
      width: 288px;
    }
  }
}
