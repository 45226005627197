@import 'src/styles/mixins';
@import 'src/styles/colors';

@include keyframe(rotate) {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.doubleBtnGroup {
  display: flex;
  .cancelBtn,
  .confirmBtn {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    height: 36px;
    justify-content: center;
    line-height: 15px;
    padding: 10px 15px 10px 15px;
    &.pending {
      color: $gray;
      pointer-events: none;
    }
  }
  .cancelBtn {
    background-color: transparent;
    color: $grayDark;
  }
  .confirmBtn {
    background-color: $WCMAccent;
    color: $white;
    &:hover {
      background-color: $WCMAccentHover;
    }
    &.pending {
      background-color: $grayLighter;
      border: 1px solid $grayLight;
      &:hover {
        background-color: $grayLighter;
      }
    }
    &.redBtn {
      background-color: $danger;
    }
    &.disabled {
      background-color: $grayLighter;
      color: $gray;
      pointer-events: none;
    }
    &.primaryButton {
      background-color: black;
      border: 3px solid #d9a81b;
      font-size: 13px;
      font-weight: 600;
      line-height: 16px;

      &:not(.disabled):hover {
        background-color: #d9a81b;
      }

      &.disabled {
        border: 3px solid #cbcbcb;
        color: #929292;
        cursor: no-drop;
        pointer-events: auto;
      }
    }
  }
  svg {
    margin-right: 10px;
    @include animation('rotate 1300ms linear infinite');
  }
  &.xl {
    .cancelBtn,
    .confirmBtn {
      padding: 10px 38px 10px 38px;
    }
  }
}
