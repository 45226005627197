@import 'src/styles/colors';

.tagsContainer {
  width: 100%;
}

.tags {
  align-items: center;
  display: flex;
  font-size: 11px;
  height: 20px;
  line-height: 20px;
  margin-left: -5px;
  margin-right: -5px;
  max-width: 100%;
  width: 100%;
  &.loading {
    visibility: hidden;
  }
}

.item {
  max-width: 116px;
  padding-left: 5px;
  padding-right: 5px;
}
.tag {
  background-color: $WCMAccent;
  border-radius: 50px;
  color: white;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}
.tagText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.counter {
  align-items: center;
  border: 1px solid $gray;
  border-radius: 12px;
  color: $gray;
  display: flex;
  height: 17px;
  padding-left: 6px;
  padding-right: 6px;
}
.addButton {
  align-items: center;
  background: none;
  border: none;
  color: $WCMAccent;
  cursor: pointer;
  display: flex;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  padding: 0;
  &:hover {
    color: $WCMAccentHover;
  }
}
.editButton {
  align-items: center;
  background: none;
  border: none;
  color: $grayDarker;
  cursor: pointer;
  display: flex;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 100%;
  padding: 3px;
  &:focus,
  &:hover {
    color: $WCMAccent;
    path {
      fill: $WCMAccent !important;
    }
  }
}
.error {
  color: $danger;
}
