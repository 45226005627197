.popupPaper {
  border-radius: 6px;
  padding: 28px 29px 40px !important;
  position: fixed !important;
  right: 100px;
  top: 50px;
  width: 550px;
  &.isOpenSidebar {
    right: 218px;
  }
  .header {
    align-items: center;
    column-gap: 12px;
    display: flex;
    padding: 0;
    & > img {
      height: 78px;
      width: 78px;
    }
  }
  .dialogContent {
    column-gap: 40px;
    display: grid;
    grid-auto-columns: 1fr auto;
    grid-template-columns: auto auto;
    margin: 28px 0;
    row-gap: 15px;
    width: 100%;
    .formControl {
      margin-left: 0;
    }
  }
  .doubleBtnGroup {
    justify-content: flex-end;
  }
}
