@import 'src/styles/colors';

.childrenContainer {
  align-items: center;
  display: flex;
  position: relative;
  .spacing {
    height: 100%;
    width: 15px;
  }
  .editIcon {
    cursor: pointer;
    display: none;
    height: 100%;
    left: 100%;
    position: absolute;
    &:hover,
    &.active {
      display: block;
      path {
        fill: $WCMAccent;
      }
    }
  }
  &:hover {
    .editIcon {
      display: block;
    }
  }
}
.editTooltipPopper {
  pointer-events: all !important;
  .editTooltip {
    background-color: $wipAreaBck;
    border: 1px solid $grayLight;
    border-radius: 0;
    margin: 2px 0;
    max-width: unset;
    padding: 7px 6px;
    .editTooltipContainer {
      align-items: flex-start;
      display: flex;
      .doubleIconBtnGroup {
        margin-left: 5px;
      }
    }
  }
  .editTooltipArrow {
    color: $wipAreaBck;
    &:before {
      border: 1px solid $grayLight;
    }
  }
}
