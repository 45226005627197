.halfMarginTransform {
  @media (min-width: 1024px) {
    transform: translateX(-173px) !important;
  }
}

.marginTransform {
  @media (min-width: 1024px) {
    transform: translateX(-345px) !important;
  }
}
