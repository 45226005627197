@import 'src/styles/colors';
@import 'src/styles/vars';

.selectedItemContainer {
  align-items: center;
  color: $WCMAccent;
  cursor: pointer;
  display: flex;
  font-family: Plaak, sans-serif;
  font-size: 11px;
  font-weight: 300;
  justify-content: flex-end;
  line-height: 13px;
  user-select: none;
  white-space: nowrap;
  .iconContainer {
    height: 16px;
    margin-left: 10px;
    width: 10px;
    & svg path {
      fill: $WCMAccent;
    }
  }
}

.dropdownList {
  background-color: $white;
  cursor: default;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 4px 20px 7px 20px;
  position: absolute;
  right: 0;
  row-gap: 13px;
  top: calc(100% + 16px);
  z-index: $dropdownZIndex;
  &:not(.withBorder) {
    align-items: flex-end;
    text-align: right;
  }
  &.withBorder {
    border: 1px solid $grayLight;
    border-radius: 6px;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.2);
  }
  .dropdownItem {
    color: $grayDarker;
    cursor: pointer;
    font-family: Plaak, sans-serif;
    font-size: 11px;
    font-weight: 300;
    line-height: 13px;
    transition: color 0.1s ease-in-out;
    user-select: none;
    white-space: nowrap;
    width: fit-content;
    &:hover {
      color: $WCMAccent;
    }

    &.activeDropdownItem {
      color: $WCMAccent !important;
    }
  }
  // footer links
  .footerLinksContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    padding-top: 20px;
    position: relative;
    row-gap: 5px;
    text-align: left;
    &:before {
      background-color: $grayLighter;
      content: '';
      height: 1px;
      left: -20px;
      position: absolute;
      top: 0;
      width: calc(100% + 40px);
    }
    & > a {
      text-decoration: none;
      &:hover {
        color: $WCMAccent;
      }
    }
    .copyright {
      margin-left: auto;
      margin-top: 10px;
    }
    .cookiesSettings {
      cursor: pointer;
    }
  }
}
