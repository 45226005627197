@import 'src/styles/colors';
@import 'src/styles/vars';

.formControl {
  align-items: flex-start !important;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 0 !important;
  position: relative;
  .formLabel,
  .inputText {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    .required {
      color: red;
    }
  }
  .formLabel {
    line-height: 16px;
    margin-top: 7px;
  }
  .inputText {
    background-color: $white;
    border: 1px solid $grayLight;
    height: 30px;
    outline: none;
    padding: 0 13px;
    transition: width $transition_timing;
    width: 230px;
    &::placeholder {
      color: $grayLight;
      font-family: SuisseIntl-RegularItalic-M, sans-serif;
      padding: 0 1px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .inputDisableCaret {
    caret-color: transparent;
  }
  .inputDisabled {
    background-color: $grayLighter;
  }
  .inputIcon {
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    & > path {
      fill: $grayDark;
    }
    &.active > path {
      fill: $WCMAccent;
    }
  }

  @media (max-width: $breakpoint_desktop-big) and (min-width: $breakpoint_desktop-small + 1) {
    .inputText {
      width: 187px;
    }
  }
  @media (max-width: $breakpoint_desktop-small) and (min-width: $breakpoint_tablet + 1) {
    .inputText {
      width: 308px;
    }
  }
}
.toggle {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 320px;
}
.eye {
  height: 13px;
  .hide {
    color: #d9a81b !important;
    cursor: pointer;
    margin-right: 14px;
    width: 17px;
  }
  .show {
    cursor: pointer;
    margin-right: 10px;
    width: 15px;
  }
}
.textField_showText {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.text_wcm_accent {
  color: #d9a81b !important;
}
.labelWrap {
  display: flex;
}
