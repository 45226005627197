@import 'src/styles/vars';
@import 'src/styles/colors';

.button {
  background-color: transparent;
  border: none;
  color: $grayDarker;
  cursor: pointer;
  font-family: Plaak, sans-serif;
  font-size: 11px;
  line-height: 13px;
  margin-bottom: 30px;
  margin-right: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative;
  &:after {
    background-color: $WCMAccent;
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: 0.2s ease;
    width: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

.selectedButton {
  color: $WCMAccent;
  &:after {
    width: 100%;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .button {
    margin-bottom: 18px;
  }
  .hideInMobile {
    display: none;
  }
}
