.responseAlert {
  border: 1px solid;
  border-radius: 6px;
  font-size: 13px;
  padding: 24px 40px 24px 24px;
  position: relative;
  width: 100%;

  &_close {
    color: #929292;
    cursor: pointer;
    height: 0.82rem;
    position: absolute;
    right: 24px;
    top: 26px;
    width: 0.82rem;
  }

  &_icon {
    height: 16px;
    left: 20px;
    position: absolute;
    top: 24px;
    width: 16px;

    &_success {
      path {
        fill: green;
      }
    }

    &_warning {
      path {
        fill: yellow;
      }
    }

    &_error {
      color: #d42b3a;
      path {
        fill: #d42b3a;
      }
    }
  }

  &_wrapper {
    padding: 0 26px;
  }

  &_error {
    border-color: #d42b3a;
  }

  &_success {
    border-color: green;
  }

  &_warning {
    border-color: yellow;
  }
}
