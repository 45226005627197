@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation($animate...) {
  $max: length($animate);
  $animations: '';

  @for $i from 1 through $max {
    $animations: #{$animations + nth($animate, $i)};

    @if $i < $max {
      $animations: #{$animations + ', '};
    }
  }

  -webkit-animation: $animations;
  -moz-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin icon-background($icon, $size) {
  background: url('../assets/#{$icon}.svg') no-repeat center transparent;
  background-size: $size $size;
  height: $size;
  width: $size;
}
