@import 'src/styles/colors';

.optionContainer {
  display: flex;
  .optionInitials {
    align-items: center;
    background-color: $grayDark;
    border-radius: 50%;
    color: $white;
    display: flex;
    height: 26px;
    justify-content: center;
    margin-right: 10px;
    width: 26px;
    &.optionTeamInitials {
      background-color: $WCMAccent;
    }
  }
  &.selectedOption {
    span {
      color: $WCMAccent;
    }
  }
  span {
    display: block;
  }
  &:hover {
    span {
      color: $WCMAccent;
    }
  }
}

.paper {
  .chipRoot {
    background-color: $WCMAccent;
    color: white;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    &.chipGray {
      background-color: $grayDark;
    }
    svg {
      color: white;
      height: 10px;
      margin-right: 10px;
      width: 10px;
    }
  }
}

.labelRoot {
  .label {
    margin-bottom: 6px;
    .required {
      color: red;
    }
  }
  .chipRoot {
    background-color: $WCMAccent;
    color: white;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    &.chipGray {
      background-color: $grayDark;
    }
    svg {
      color: white;
      height: 10px;
      margin-right: 10px;
      width: 10px;
    }
  }
}
