@import 'src/styles/colors';

.dialog {
  .paper {
    border-radius: 6px;
    padding: 30px;
  }
  .dialogHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
    svg {
      cursor: pointer;
    }
  }
  .infoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    max-height: 350px;
    min-height: 210px;
    overflow: auto;
    row-gap: 20px;
    width: 454px;
  }
  .infoItem {
    display: flex;
    flex-direction: column;
    width: 206px;
    .infoTitle {
      margin-bottom: 5px;
    }
    .infoDetail {
      word-break: break-word;
      .workCode {
        margin-left: 3px;
        a {
          text-decoration: none;
        }
      }
    }
    &.infoItemMsg {
      min-width: 206px;
      width: unset;
    }
  }
  .doubleBtnGroup {
    justify-content: flex-end;
  }
}
