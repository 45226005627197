@import 'src/styles/vars';
@import 'src/styles/colors';

.playBar {
  background-color: $white;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 31px 20px 12px 30px;
  position: absolute;
  transition: padding $transition_timing;
  width: 100%;
  z-index: $playBarZIndex;
  .waveFormContainer {
    margin: 0 auto;
    padding-top: 5px;
    width: 100%;
    .waveForm {
      height: 36px;
    }
  }
  &.darkPlaybar {
    background-color: #000000;
  }
  &.playbarOpenedMobileMarkers {
    z-index: $mobilPlayBarZIndex + 1;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .playBar {
    padding: 0;
    z-index: $mobilPlayBarZIndex;
  }
}
