@import 'src/styles/colors';

.statusCard,
.statusAdditionalCard {
  border-radius: 12px;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
}
.statusCard {
  background-color: $gray;
  color: $white;
  line-height: 18.5px;
  margin-right: 6px;
  max-width: 100%;
  padding: 2px 10px;
  width: auto;
}
.statusAdditionalCard {
  background-color: $white;
  border: 1px solid $gray;
  color: $gray;
  cursor: pointer;
  display: unset;
  padding: 1px 6px;
  position: relative;
}
