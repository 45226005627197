@import 'src/styles/colors';
@import 'src/styles/vars';

.formControl {
  align-items: flex-start !important;
  justify-content: space-between;
  margin-left: 0 !important;
  label {
    display: none;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .formLabel {
    color: $grayDarker !important;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-top: 7px;
    white-space: nowrap;
    .required {
      color: red;
    }
  }
  .inputRoot {
    cursor: default !important;
    margin-top: 0 !important;
    min-height: 30px;
    padding: 3px 27px 2px 10px !important;
    &:before,
    &:after {
      display: none;
    }
    .tag {
      background-color: $WCMAccent;
      color: #ffffff;
      height: 19px;
      padding: 3px 9px;
      span {
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 11px;
        line-height: 13px;
        padding: 0 7px 0 1px;
      }
      svg {
        height: 7px;
        margin: 0;
        width: 7px;
      }
    }
    .input {
      color: $grayDarker;
      font-size: 13px;
      padding: 0 10px 0 4px !important;
      &::placeholder {
        color: $grayDark;
        font-family: SuisseIntl-RegularItalic-M, sans-serif;
        padding: 0 1px;
      }
    }
    .endAdornment {
      align-items: center;
      display: flex;
      height: 10px;
      justify-content: center;
      right: 16px;
      top: 10px;
      svg {
        height: 11px;
      }
    }
  }
}

.disabled {
  background-color: $grayLighter !important;
}

.root {
  background-color: $white;
  border: 1px solid $grayLight;
  margin-left: 15px;
  transition: width $transition_timing, height $transition_timing !important;
  width: 230px;
}

.error {
  background-color: $grayLighter;
  border: 1px solid $danger !important;
}

.clearIndicatorBtn {
  height: 15px;
  margin-right: 2px !important;
  width: 15px;
}

.popper {
  min-width: 145px;
  width: unset !important;
  z-index: $bellowConfirmPopupZIndex !important;
}
.paper {
  border-radius: unset !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3) !important;
  margin: 0 !important;
  padding: 8px;
}
.listbox {
  background-color: $white;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 15px;
  max-height: 156px !important;
  overflow-y: auto !important;
  padding: 0 !important;
  li {
    background-color: transparent !important;
    &:not(:last-child) {
      padding: 0 0 20px 0;
    }
    &:last-child {
      padding: 0;
    }
    &[aria-selected='false'] {
      color: $grayDarker;
    }
    &[aria-selected='true'] {
      color: $WCMAccent;
    }
  }
}

@media (max-width: $breakpoint_desktop-big) and (min-width: $breakpoint_desktop-small + 1) {
  .root {
    width: 187px;
  }
}

@media (max-width: $breakpoint_desktop-small) and (min-width: $breakpoint_tablet + 1) {
  .root {
    width: 308px;
  }
}
.infoIcon {
  margin-left: 5px;
}
