@import './mixins.scss';
@import './colors.scss';
@import './vars.scss';
@import './fonts.scss';

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  inset: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  width: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

html,
body,
button,
div,
p,
h1,
img,
input {
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p,
ul,
button,
input {
  margin: 0;
  padding: 0;
}
button:focus {
  outline: none;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

.app {
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  .app-container {
    height: 100%;
    position: relative;
  }
  @media (min-width: $breakpoint_desktop-small) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.ag-root-wrapper {
  .ag-header {
    background-color: $wipAreaBck !important;
    border-bottom: none !important;
    .ag-header-cell {
      background-color: $wipAreaBck !important;
      color: $grayDarker;
      .ag-header-cell-resize {
        background-color: $grayLighter !important;
        transform: translateX(-4px);
      }
    }
  }
  .ag-center-cols-container {
    min-width: 100%; // TODO: change this, need fix flex
  }
  .ag-row:hover {
    background-color: $grayLighter !important;
  }
  .ag-row-selected {
    background-color: $white !important;
  }
  .ag-overlay-no-rows-center {
    transform: translateY(28px);
  }
}
.ag-cell {
  border-color: transparent !important;
}

.actionsCell {
  justify-content: flex-start;
  padding: 0 0 0 14px !important;
  pointer-events: none;
  z-index: $actionsCellZIndex;
  > .ag-react-container {
    height: 100%;
    pointer-events: all;
  }
}

.ag-pinned-right-cols-container {
  .actionsCell {
    background-color: $white;
  }
}

.MuiInputBase-input {
  min-height: 20px;
}

.MuiTooltip-popper {
  z-index: $bellowConfirmPopupZIndex !important;
}

::-webkit-scrollbar {
  height: 3px;
  position: absolute;
  width: 3px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #e2e2e2;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a0a0a0;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b4b4b4;
  cursor: pointer;
}
