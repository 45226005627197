.iconButton {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  svg {
    height: 20px;
    width: 20px;
  }
  span {
    margin-left: 9px;
  }
}
