@import 'src/styles/vars';
@import 'src/styles/colors';

.menuPaper {
  background-color: $white !important;
  border-radius: 0 !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
  margin: 25px 0 0 -8px;
  padding: 30px 0 30px 30px;
  width: 185px;
  .menuList {
    padding: 0;
    li {
      color: $grayDarker;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      min-height: auto;
      padding: 0;
      width: fit-content;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &:hover {
        background-color: transparent;
        color: $WCMAccent;
      }
    }
  }
}

.moreItem {
  color: $grayDarker;
  cursor: pointer;
  font-family: SuisseIntl-Regular, sans-serif;
  font-size: 13px;
  line-height: 24px;
  &.disabledItem {
    color: $grayLight;
    pointer-events: none;
  }
  &:hover {
    color: $WCMAccent !important;
  }
}
.label {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 14px !important;
  svg {
    margin-left: 6px;
    transform: scale(1.4);
  }
}
