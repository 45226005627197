@import 'src/styles/mixins';
@import 'src/styles/vars';

@include keyframe(rotate) {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  @include animation('rotate 1300ms linear infinite');
  vertical-align: middle;
}
