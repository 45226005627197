@import 'src/styles/colors';

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 21px 14px 0;
  min-height: 20px;
  svg {
    cursor: pointer;
    &.filterIcon {
      margin-top: 2px;
      min-height: 15px;
      min-width: 15px;
      &.openedFilters path {
        fill: $WCMAccent;
      }
    }
  }
  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    width: 100%;
    h2 {
      color: $grayDarker;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-right: 10px;
    }
    .tag {
      align-items: center;
      background-color: $white;
      border: 1px solid $grayLight;
      border-radius: 50px;
      color: $gray;
      display: flex;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 10px;
      margin-right: 12px;
      min-height: 20px;
      padding: 0 11px 0 14px;

      span {
        color: $grayDarker;
        margin-right: 3px;
      }

      svg {
        margin-left: 7px;
        min-width: 8px;

        path {
          fill: $grayLight;
        }
      }
    }
  }
}
