@import 'src/styles/colors';
@import 'src/styles/vars';

.formControl {
  align-items: flex-start !important;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 0 !important;
  position: relative;
  .formLabel,
  .inputText {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    .required {
      color: red;
    }
    .labelInfoIcon {
      margin-left: 7px;
    }
  }
  .formLabel {
    align-items: center;
    display: flex;
    line-height: 16px;
    margin-top: 7px;
  }
  .inputText {
    background-color: $white;
    border: 1px solid $grayLight;
    height: 30px;
    outline: none;
    padding: 0 13px;
    transition: width $transition_timing;
    width: 230px;
    &::placeholder {
      color: $grayLight;
      font-family: SuisseIntl-RegularItalic-M, sans-serif;
      font-weight: 200;
      padding: 0 1px;
    }
  }
  .inputError {
    border-bottom: 1px solid $danger !important;
  }
  .inputIcon {
    position: absolute;
    right: 16px;
    top: 8px;
    & > path {
      fill: $grayDark;
    }
    &.active > path {
      fill: $WCMAccent;
    }
    &.error > path {
      fill: $danger;
    }
  }

  @media (max-width: $breakpoint_desktop-big) and (min-width: $breakpoint_desktop-small + 1) {
    .inputText {
      width: 187px;
    }
  }
  @media (max-width: $breakpoint_desktop-small) and (min-width: $breakpoint_tablet + 1) {
    .inputText {
      width: 308px;
    }
  }
}
