.paperRoot {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  max-width: none !important;
  padding: 16px;
}

.playbarRelatedLayer {
  z-index: 35 !important;
}

.playbarRelatedMargin {
  max-height: calc(100% - 270px) !important;
}
