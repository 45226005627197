@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/vars';

.disablePlayButton {
  cursor: default !important;
  path {
    fill: $grayLight;
  }
}

.songs {
  .songsHeading {
    display: flex;
    justify-content: space-between;
    .newSongBtn {
      background-color: $WCMAccent;
      border: 1px solid $WCMAccent;
      color: $white;
      cursor: pointer;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      height: 35px;
      line-height: 16px;
      width: 92px;
      &:hover {
        background-color: $WCMAccentHover;
      }
    }
  }
  .songTabs {
    height: calc(100% - 75px);
  }
  .songTabsContent {
    height: calc(100% - 101px);
  }
}

.dataGridContainer {
  height: calc(100% - 90px - 35px - 19px - 56px - 37px - 8px);
  margin-top: 18px;
  min-height: $tableMinHeight;
  overflow: unset !important;
  padding-right: 0 !important;
  position: relative;
  width: 100%;
  &.dataGridBigHeight {
    height: calc(100% - 15px);
  }
  &.dataGridOnlyAllTab {
    height: calc(100% - 110px); // 101px SongsTabsContent height
    margin-top: 0;
  }
  .selectedItemsActionContainer {
    align-items: center;
    background-color: $WCMAccent;
    display: flex;
    height: 49px;
    justify-content: space-between;
    padding: 0 25px 0 19px;
    position: absolute;
    top: -12px;
    width: 100%;
    z-index: 1;
    &::before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $WCMAccent;
      content: '';
      height: 0;
      left: 23px;
      position: absolute;
      top: 100%;
      width: 0;
    }
    .selectedItemsCountContainer {
      align-items: center;
      color: $white;
      display: flex;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      svg {
        cursor: pointer;
        height: 10px;
        margin-left: 7px;
        width: 10px;
      }
    }
    .selectedItemsActionButtonsContainer {
      align-items: center;
      display: flex;
      .selectedItemsActionButton {
        background-color: transparent;
        border: none;
        color: $white;
        cursor: pointer;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
        line-height: 16px;
        margin-left: 29px;
        &.disabled {
          cursor: default;
          opacity: 0.8;
        }
      }
    }
  }
  .selectedItemsDetailsPanel {
    align-items: center;
    background-color: $wipAreaBck;
    border-bottom: 1px solid $grayLight;
    display: flex;
    height: 106px;
    padding: 30px 15px;
    .labelViewSwitchContainer {
      display: flex;
      flex-direction: column;
      .labelViewTitleContainer {
        align-items: center;
        display: flex;
        gap: 8px;
        svg {
          height: 13px;
          width: 13px;
        }
      }
    }
    .labelViewForm {
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
    .labelSwitchRoot {
      align-self: start;
    }
  }
  .dataGrid {
    height: 100%;
    width: 100%;
    :global {
      .settings {
        background-color: transparent;
      }
      .panel {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
        padding: 30px 0 20px 30px;
        &::after {
          content: '';
          height: 30px;
          position: absolute;
          width: 1px;
        }
        .title {
          color: $gray;
          font-size: 9px;
          font-weight: normal;
          line-height: 11px;
          text-transform: uppercase;
        }
      }
      .ag-icon.ag-icon-columns {
        @include icon-background('more', 16px);
        background-color: $wipAreaBck;
        transform: rotate(90deg);
        &:before {
          content: '' !important;
        }
      }
      .ag-checkbox-input-wrapper {
        @include icon-background('unchecked-table', 18px);
        box-shadow: none !important;
        height: 18px !important;
        width: 18px !important;
        &:before {
          content: '' !important;
        }
        &::after {
          color: transparent;
        }
        input {
          cursor: pointer;
        }
        &.ag-checked {
          @include icon-background('checked-table', 18px);
          &:before {
            content: '' !important;
          }
          &::after {
            color: transparent;
          }
        }
        &.ag-indeterminate {
          @include icon-background('unchecked-table', 18px);
          &:before {
            content: '' !important;
          }
          &::after {
            color: transparent;
          }
        }
      }
    }
    .actionsHeader {
      justify-content: flex-start;
      padding: 0;
      .activeIcon {
        path {
          fill: $WCMAccent;
        }
      }
    }
    .headerCell {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 13px;
      font-weight: 500;
    }
    .playIconHeaderCell {
      z-index: 2;
      svg {
        cursor: pointer;
        &.disabledPlayIcon {
          pointer-events: none;
          path {
            fill: $grayLight;
          }
        }
      }
    }
    .detailRowContainer {
      align-items: center;
      display: flex;
      height: 107px;
      justify-content: flex-start;
      padding: 0 113px 0 73px;
      width: 100%;
      svg {
        cursor: pointer;
        &:hover path {
          fill: $WCMAccent;
        }
      }
      .hiddenColItemsContainer {
        column-gap: 40px;
        display: grid;
        grid-auto-columns: 1fr auto;
        grid-template-columns: auto auto auto auto auto;
        row-gap: 15px;
        width: 100%;
        .hiddenColItemContainer {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
          .expandedHeaderNames {
            color: $gray;
            font-family: SuisseIntl-SemiBold-M, sans-serif;
            font-size: 11px;
            line-height: 13px;
          }
          .defaultValueGetter {
            color: $grayDarker;
            font-family: SuisseIntl-Regular-M, sans-serif;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }

    .cellContainer {
      align-items: center;
      color: $grayDarker;
      display: flex;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      :global {
        .ag-react-container {
          width: 100%;
        }
      }
      .songTitleContainerWithWriter,
      .songTitleContainerWithVersion {
        display: contents;
      }
      .songTitle {
        margin-right: 10px;
        max-width: calc(100% - 10px);
        width: unset;
      }
      .heartIcon {
        height: 14px;
        width: 15px;
      }
      svg {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
      }
      .actionsCellContainer {
        display: flex;
        height: 100%;
        .actionsCellMain {
          align-items: center;
          background-color: $white;
          display: flex;
          height: 100%;
          svg {
            height: 30px;
            padding: 8px;
            width: 30px;
            &:hover path {
              fill: $WCMAccentHover;
            }
            &.disabledActionBtn {
              opacity: 0.5;
              pointer-events: none;
            }
            &:not(:last-child) {
              margin-right: 8px;
            }
          }
          &.actionsCellMainTablet {
            display: none;
            svg:last-of-type {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
    .statusCellContainer {
      overflow: visible;
    }
    .expandedRow {
      background-color: $white;
      overflow: visible;
      &:before {
        background-color: $white;
        content: '';
        height: 2px;
        position: absolute;
        top: -1px;
        width: 100%;
      }
    }
  }
  @media (min-width: $breakpoint_tablet) and (max-width: $breakpoint_desktop-small) {
    .dataGrid {
      .cellContainer {
        .actionsCellContainer {
          overflow: visible;
          .actionsCellMain {
            &.actionsCellBase {
              display: none;
            }
            &.actionsCellMainTablet {
              background-color: transparent;
              display: block;
            }
          }
        }
      }
    }
  }
  @media (max-width: $breakpoint_tablet - 1) {
    .dataGrid {
      .cellContainer {
        .actionsCellContainer {
          overflow: visible;
          .actionsCellMain {
            &.actionsCellBase {
              display: none;
            }
            &.actionsCellMainTablet {
              background-color: transparent;
              display: block;
            }
            &.actionsCellMainMobile {
              :global .expand {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.heading {
  color: $grayDarker;
  display: inline-block;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 35px;
  margin-top: 30px;
}

.myUploadTableFilters {
  margin-top: 50px;
}

.songVersionInTitle {
  align-items: center;
  display: flex;
}

@media (min-width: $breakpoint_tablet) {
  .showOnlyInMobile {
    display: none !important;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .dataGridContainer {
    margin-top: 0;
  }
  .hideInMobile {
    display: none !important;
  }
  .songVersionInTitle {
    display: flex;
    margin-right: -23px;
  }
  .heartIcon {
    margin-right: -23px;
  }
  .songTitleContainerWithWriter {
    display: flex !important;
    flex-direction: column;
    gap: 6px;
    width: calc(100% - 70px);
  }
  .songTitleContainerWithVersion {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }
}
