@import 'src/styles/colors';
@import 'src/styles/vars';

.button {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 35px;
  line-height: 16px;
  &:disabled {
    cursor: default;
  }
  * {
    pointer-events: none; // To prevent issues with react-sortable-hoc
  }
}

.menuItem {
  align-items: center;
  background-color: transparent;
  border: none;
  color: $grayDarker;
  cursor: pointer;
  display: flex;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 35px;
  line-height: 16px;
  padding-left: 12px;
  padding-right: 12px;
  &:focus,
  &:hover {
    color: $WCMAccent;
  }
  &:disabled {
    color: $gray;
    cursor: default;
  }
}

.goBackButton {
  height: 19px;
  margin-bottom: 28px;
  padding: 0;
  width: 19px;
  &:focus,
  &:hover {
    color: $WCMAccent;
  }
}

.iconButton {
  color: $grayDarker;
  height: 19px;
  padding: 0;
  &:focus,
  &:hover {
    color: $WCMAccent;
  }
  &:disabled {
    color: $gray;
    cursor: default;
  }
  path {
    fill: $WCMAccent;
  }
}

.icon {
  display: inline-flex;
  vertical-align: middle;
  &.withMarginRight {
    margin-right: 8px;
  }
  &.withMarginLeft {
    margin-left: 8px;
  }
}
.spinner {
  margin-left: 8px;
}

.primaryButton {
  background-color: $WCMAccent;
  border: 1px solid $WCMAccent;
  color: white;
  padding-left: 16px;
  padding-right: 16px;
  &:focus,
  &:hover {
    background-color: $WCMAccentHover;
    border-color: $WCMAccentHover;
  }
  &:disabled {
    background-color: $grayLighter;
    border-color: $grayLighter;
    color: $gray;
    cursor: default;
  }
}

.textButton {
  color: $grayDark;
  padding-left: 12px;
  padding-right: 12px;
  path {
    fill: $grayDark;
  }
  &:focus,
  &:hover {
    color: $WCMAccent;
    path {
      fill: $WCMAccent;
    }
  }
  &:disabled {
    color: $gray;
    cursor: default;
  }
}

.popover {
  z-index: $bellowConfirmPopupZIndex !important;
}

.paperRoot {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
  padding: 18px;
  &.roundedPaper {
    border-radius: 6px;
  }
}

.customButton {
  align-items: center;
  background-color: black;
  border: 3px solid #d9a81b;
  color: white;
  display: inline-flex;
  font-size: 13px;
  font-weight: 600;
  height: 35px;
  justify-content: center;
  line-height: 16px;
  padding: 10px 15px 9px;

  &:hover {
    background-color: #d9a81b;
    cursor: pointer;
  }

  &:disabled {
    background-color: black;
    border-color: #cbcbcb;
    color: #929292;
    cursor: no-drop;
  }
}
