@import 'src/styles/mixins';
@import 'src/styles/colors';

.doubleIconBtnGroup {
  display: flex;
  .cancelBtn,
  .confirmBtn {
    align-items: center;
    border: none;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    &.disabled {
      background-color: $grayLighter;
      color: $gray;
      pointer-events: none;
    }
  }
  .cancelBtn {
    background-color: $grayLighter;
    border: 1px solid $grayLight;
    color: $grayDark;
    width: 39px;
    &:hover {
      background-color: $grayLight;
    }
  }
  .confirmBtn {
    background-color: $WCMAccent;
    color: $white;
    width: 43px;
    &:hover {
      background-color: $WCMAccentHover;
    }
  }
}
