@import 'src/styles/colors';

.textEditor {
  min-height: 144px;
  width: 480px;
  :global {
    .ql-toolbar {
      background-color: $wipAreaBck;
      border: 1px solid $grayLight;
      height: 30px;
      padding: 2px;
      button {
        &:hover {
          svg {
            .ql-stroke,
            .ql-fill {
              stroke: $WCMAccent;
            }
          }
        }
        svg {
          .ql-stroke,
          .ql-fill {
            stroke: $grayDark;
          }
          &:hover {
            .ql-stroke,
            .ql-fill {
              stroke: $WCMAccent;
            }
          }
        }
        &.ql-active {
          svg {
            .ql-stroke,
            .ql-fill {
              stroke: black;
            }
          }
        }
      }
    }
    .ql-container {
      background-color: $white;
      border-color: $grayLight;
      color: $grayDarker;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      max-height: 200px;
      min-height: 114px;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      .ql-editor {
        a {
          color: $WCMAccent;
        }
      }
    }
    .ql-tooltip {
      background-color: $wipAreaBck;
      &.ql-flip {
        transform: translateY(30px);
      }
      a {
        color: $WCMAccent;
      }
      input:focus {
        outline: none;
      }

      &:before {
        content: 'Add URL' !important;
      }
    }
  }
}
