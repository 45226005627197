@import 'src/styles/vars';
@import 'src/styles/colors';

.notificationsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 350px;
  .notificationsHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .btnsContainer {
      button {
        background-color: transparent;
        border: none;
        max-height: 16px;
        width: unset;
        &:hover {
          background-color: transparent;
          span {
            color: $WCMAccent;
          }
          svg path {
            fill: $WCMAccent;
          }
        }
      }
    }
  }
  .notificationsListItemsContainer {
    flex: 1;
    .notificationRow {
      align-items: center;
      border-bottom: 1px solid $grayLight;
      cursor: pointer;
      display: flex;
      height: 55px;
      .dot {
        background-color: $WCMAccent;
        border-radius: 50%;
        height: 10px;
        min-width: 10px;
        &.hidden {
          visibility: hidden;
        }
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        .messageText {
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .actions {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        min-width: 140px;
        width: 140px;
      }
    }
  }
  // In popup styles
  &.inPopup {
    .notificationsHeader {
      margin-bottom: unset;
    }
    .notificationsHeader,
    .notificationsFilter {
      padding: 0 30px;
    }
    .notificationsFilter {
      display: flex;
      margin-top: 20px;
      span {
        cursor: pointer;
        &:first-child {
          margin-right: 15px;
        }
        &.activeNotificationFilterType {
          color: $WCMAccent;
        }
      }
    }
    .notificationsListItemsContainer {
      margin-top: 10px;
      max-height: 350px;
      overflow: auto;
      .notificationRow {
        border-bottom: unset;
        border-left: 2px solid transparent;
        height: 69px;
        padding: 9px 13px 9px 30px;
        transition: background-color $transition_timing;
        &:hover {
          background-color: $wipAreaBck;
          border-left: 2px solid $WCMAccent;
        }
        .textContainer {
          padding: 0 15px;
          .messageText {
            -webkit-line-clamp: 2;
            margin-bottom: 5px;
            word-break: break-word;
          }
        }
        .moreIcon {
          cursor: pointer;
          min-width: 13px;
          transform: rotate(90deg);
        }
      }
    }
  }
  // In page styles
  &.inPage {
    .notificationRow {
      column-gap: 20px;
      padding: 9px 30px 9px 2px;
      .textContainer {
        margin-right: auto;
        overflow: hidden;
        .messageText {
          -webkit-line-clamp: 1;
        }
      }
    }
  }
}
