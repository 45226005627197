@import 'src/styles/colors';

.toggleBtnGroup {
  height: 35px;
  .toggleBtn {
    color: $grayDarker;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    padding: 10px 10px 9px;
    text-transform: none;
    white-space: nowrap;
    &:hover {
      background-color: $grayLighter;
    }
    &:not(.selected) {
      background-color: $white;
      border-color: $grayDarker;
      z-index: 2;
    }
    &.selected {
      background-color: black !important;
      border-color: black !important;
      color: $white;
      &:hover {
        background-color: $WCMAccentHover;
        border-color: $WCMAccentHover;
      }
    }
  }
}

.xs {
  height: 25px;
  .toggleBtn {
    border-radius: 6px;
    font-size: 11px;
    line-height: 13px;
    padding: 6px 10px;
  }
}
