// --grid-grid-header-color: #f4f4f4;
// --grid-header-active-color: #e5e5e5;

.container {
  position: absolute;
  right: 0px;
  top: 0px;
  user-select: none;
}

.settings {
  align-items: center;
  // background-color: #f4f4f4;
  background-color: var(--grid-header-color);
  cursor: pointer;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  width: 3.5rem;
  &:hover {
    // background-color: #e5e5e5;
    background-color: var(--grid-header-active-color);
  }
}

.opened {
  // background-color: #e5e5e5;
  background-color: var(--grid-header-active-color);
}

.panel {
  background-color: #fff;
  border-width: 1px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  cursor: default;
  padding: 1.25rem;
  position: absolute;
  right: 0px;
  top: 3.5rem;
  width: 13rem;

  .title {
    color: #646464;
    font-size: 11px;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.7rem;
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    // margin-right: -0.5rem;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-top: 0.25rem;
    > * + * {
      --tw-space-y-reverse: 0;
      margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
      margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    }

    .item {
      align-items: center;
      cursor: pointer;
      display: flex;
      > * + * {
        --tw-space-x-reverse: 0;
        margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
        margin-right: calc(0.5rem * var(--tw-space-x-reverse));
      }

      :global {
        .ag-checkbox-input-wrapper {
          font-size: 14px;
          height: 14px;
          line-height: 14px;
          width: 14px;
        }
        input[type='checkbox' i] {
          cursor: pointer;
        }
      }

      .checkbox {
        position: relative;
      }

      .name {
        flex-grow: 1;
        // font-size: 0.875rem;
        font-size: 13px;
        line-height: 1.25rem;
      }
    }
  }
}
