.waveOuterDiv {
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  position: relative;
}

.waveInnerDiv {
  height: 100%;
  position: absolute;
}

.waveform {
  height: 100%;
}
