@import 'src/styles/colors';

.autocomplete {
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  max-height: 200px;
  overflow: auto;
  width: 100%;

  fieldset {
    border: 1px solid $grayLight !important;
    border-radius: 0;
  }

  input {
    box-sizing: border-box;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 3px;
    margin-top: 3px;
    min-width: 40px;
    padding: 3px !important;
    &::placeholder {
      color: $gray;
      font-style: italic;
      letter-spacing: normal;
      opacity: 1;
    }
  }
  .chipRoot {
    background-color: $WCMAccent;
    color: white;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    svg {
      color: white;
      height: 10px;
      margin-right: 10px;
      min-width: 10px;
      width: 10px;
    }
  }
  :global {
    .MuiInputBase-root {
      background-color: white;
      min-height: 35px;
      padding: 3px 50px 3px 12px !important;
    }
    .MuiAutocomplete-endAdornment {
      align-items: center !important;
      display: flex !important;
      height: 100%;
      right: 15px !important;
      top: 0;
    }
    .Mui-disabled {
      background-color: $grayLighter;
    }
    .Mui-focused {
      input::placeholder {
        color: $gray;
      }
      fieldset {
        border-color: $WCMAccent !important;
      }
    }
    .Mui-error {
      input::placeholder {
        color: $danger;
      }
      fieldset {
        border-color: $danger !important;
      }
    }
  }
}

.paper {
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  margin: 0 !important;
  :global {
    .MuiAutocomplete-listbox {
      margin: 8px;
      padding: 0;
    }
    .MuiAutocomplete-option {
      padding-left: 8px;
      padding-right: 8px;
      &[aria-selected='true'],
      &[data-focus='true'] {
        background-color: transparent;
        color: $WCMAccent;
      }
    }
  }
  .separator {
    border-bottom: 0;
    border-color: $grayLighter;
    border-style: solid;
    margin: 0;
  }
  .addNewButton {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    min-height: 37px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    word-break: break-all;
    &:focus,
    &:hover {
      color: $WCMAccentHover;
    }
  }
}

.clearIndicator {
  height: 15px;
  margin-right: 2px !important;
  width: 15px;
  svg {
    height: 11px;
    path {
      fill: $gray;
    }
  }
}

.popupIndicatorOpen {
  transform: none !important;
}

.label {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  &:empty {
    display: none;
  }
}

.labelPlacementTop {
  align-items: flex-start !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  :global {
    .MuiFormControlLabel-label {
      margin-bottom: 8px;
    }
    .MuiAutocomplete-popperDisablePortal {
      top: calc(100% - 5px);
    }
  }
}

.searchIcon path {
  fill: $gray;
}

.requiredIcon {
  background-color: $grayDark;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  margin-left: 6px;
  width: 8px;
  &.errorColor {
    background-color: $danger;
  }
}

.popupIndicator {
  pointer-events: none;
}
