@import 'src/styles/colors';

.contactsHeader {
  display: flex;
  .pageHeader {
    flex-grow: 1;
  }
}

.formPaper {
  width: 565px;
  .formTitle {
    h2 {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .formContent {
    padding-bottom: 24px !important;
    padding-top: 24px !important;
  }
  .formActions {
    padding: 16px 24px;
  }
  .formSecondaryTitle {
    color: $gray;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
}

.control {
  margin-bottom: 18px;
}

.title {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

.secondaryTitle {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
}

.titleEditTooltip {
  display: inline-flex !important;
  margin-bottom: 30px;
}

.chevronUp {
  transform: rotate(180deg);
}

.moreIcon {
  transform: rotate(90deg);
}

.expandedRow {
  display: flex;
  margin-top: -10px;
  padding-left: 75px;
  width: 100%;
  .rowBlock {
    margin-right: 50px;
    .label {
      color: $gray;
      display: block;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 11px;
      line-height: 13px;
      margin-bottom: 6px;
    }
  }
}

.tabs {
  height: calc(100% - 89px);
  .tabContent {
    height: calc(100% - 63px);
  }
}

.dataGridFullHeight {
  height: 100%;
}

.dataGridHeightWithFilters {
  height: calc(100% - 34px);
}
