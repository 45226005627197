@import 'src/styles/colors';

.columnConfigContainer {
  background-color: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  max-height: 260px;
  padding: 30px 0 20px 30px;
  position: absolute;
  right: 0;
  top: 55px;
  width: 174px;
  .columnConfigTitle {
    color: $gray;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 9px;
    line-height: 11px;
    margin-bottom: 11px;
  }
  .columnConfigItems {
    display: flex;
    flex-direction: column;
    max-height: 180px;
    row-gap: 18px;
    .formControl {
      height: 16px;
      margin-left: -5px;
    }
  }
}
