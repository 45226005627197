@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute;
  z-index: $popupsZIndex;
  .dialogPaper {
    align-items: center;
    background-color: $DarkBck;
    border: 1px solid $WCMGold;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    max-height: unset;
    padding: 98px 82px;
    position: fixed;
    row-gap: 45px;
    .registerContainer {
      align-items: center;
      display: flex;
      flex-direction: column;
      row-gap: 25px;
      :global {
        .MuiFormControlLabel-root {
          margin: unset;
        }
      }
    }
  }
  .dialogBackdrop {
    background-color: rgba(0, 0, 0, 0.83);
  }
}

.registerInput {
  background-color: rgba(0, 0, 0, 0.5) !important;
  border: 2px solid $grayDark !important;
  border-radius: 20px;
  color: $white !important;
  font-size: 15px !important;
  font-weight: 500;
  height: 45px !important;
  line-height: 18px !important;
  width: 348px !important;
  &::placeholder {
    font-family: SuisseIntl-Regular-M, sans-serif !important;
  }
  &:focus {
    border-color: $WCMAccent !important;
  }
  &.registerInputError {
    border-color: $danger !important;
  }
}

.registerAutocomplete {
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid $grayDark;
  border-radius: 20px;
  height: 45px;
  width: 348px;
  &:focus-within {
    border-color: $WCMAccent;
  }
  input {
    color: $white !important;
    font-size: 15px !important;
    height: 100%;
    line-height: 18px;
    &::placeholder {
      color: $grayLight !important;
      font-family: SuisseIntl-Regular-M, sans-serif !important;
      opacity: 1;
    }
  }
  :global {
    .MuiFormControl-root,
    .MuiInputBase-root {
      height: 100%;
    }
  }
  .registerAutocompleteEndAdornment {
    right: 20px;
    top: 10px;
    svg > path {
      fill: $grayDark;
      stroke: $grayDark;
    }
  }
}

.registerAutocompletePaper {
  border: 1px solid #646464;
  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  transform: translateX(-9px);
  width: 330px;
  ul {
    background-color: $DarkBck;
    min-height: 165px;
    padding: 30px 0 !important;
    li {
      align-items: center;
      color: $wipAreaBck !important;
      display: flex;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      height: 35px;
      line-height: 16px;
      padding: 0 0 0 18px !important;
      position: relative;
      &:hover {
        background-color: $grayDarker !important;
        &:after,
        &:before {
          content: '';
          height: 100%;
          position: absolute;
        }
        &:after {
          background-color: $grayDarker;
          width: calc(100% - 18px);
          z-index: -1;
        }
        &:before {
          background-color: $white;
          left: 0;
          width: 2px;
        }
      }
    }
  }
}

.registerBtn {
  background-color: rgba(0, 0, 0, 0.9);
  border: 5px solid transparent;
  border-image: url('../../../assets/gold_texture.jpeg') 15 stretch;
  border-radius: 10px;
  bottom: 0;
  color: $white;
  cursor: pointer;
  font-family: Plaak, sans-serif;
  font-size: 13px;
  height: 40px;
  letter-spacing: 3px;
  line-height: 16px;
  width: 170px;
  &:hover {
    background-color: rgb(17, 24, 39, 0.9);
  }
}

.errorText {
  color: $danger;
}
