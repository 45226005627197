@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute !important;
  top: -30px !important;
  z-index: $popupsZIndex !important;
  .dialogPaper {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    max-height: unset;
    max-width: unset;
    overflow: visible;
    padding: 33px 43px 43px 40px;
    position: fixed;
  }
  .dialogBackdrop {
    background-color: transparent;
  }
  .dialogHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    row-gap: 8px;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
      &:last-child {
        color: $gray;
      }
    }
    .addTitle {
      max-width: 482px;
    }
  }
  .addDoubleBtnContainer {
    justify-content: flex-end;
    margin-top: 30px;
  }
}
