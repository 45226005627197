@import 'src/styles/colors';
@import 'src/styles/vars';

.requestAccessContainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1024px;
  padding: 100px;
  position: relative;
  .titleText {
    color: $grayDarker;
    font-family: Plaak, sans-serif;
    font-size: 16px;
    font-weight: 500;
    height: 27px;
    line-height: 19px;
    margin-bottom: 12px;
    margin-top: 34px;
  }
  .infoText {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 14px;
    line-height: 18px;
    max-width: 366px;
    & > a {
      color: $WCMAccent;
    }
    &.assistance {
      margin-top: 22px;
    }
    &.signedInAs {
      margin-bottom: 12px;
      margin-top: 34px;
    }
  }
  .tag {
    align-items: center;
    background-color: $white;
    border: 1px solid $grayLight;
    border-radius: 50px;
    display: flex;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 11px;
    height: 20px;
    padding: 0 11px 0 14px;
    span {
      color: $grayDarker;
      margin-right: 3px;
    }
  }
  .logoutBtn {
    background-color: transparent;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 11px;
    height: 16px;
    margin-top: 12px;
    user-select: none;
    &:hover {
      color: $WCMAccentHover;
    }
  }
  .accessLogo {
    position: absolute;
    right: 100px;
    z-index: -1;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .requestAccessContainer {
    padding: 32px;
    .accessLogo {
      display: none;
    }
  }
}

@media (max-width: 300px) {
  .notPendingBreak {
    display: none;
  }
}

@media (max-width: $breakpoint_mobile) {
  .pendingBreak {
    display: none;
  }
}
