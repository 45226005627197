@import 'src/styles/colors';
@import 'src/styles/vars';

.root {
  z-index: $bellowConfirmPopupZIndex !important;
}
.paper {
  width: 565px;
}

.title {
  h2 {
    color: $grayDarker;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 16px;
    line-height: 20px;
  }
}

.content {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
}

.actions {
  padding: 16px 24px !important;
}
