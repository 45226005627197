.datagrid {
  position: relative;
  :global {
    .ag-center-cols-viewport {
      height: calc(100% + 16px) !important;
    }
    .ag-body-horizontal-scroll-viewport {
      height: 3px !important;
      max-height: 3px !important;
      min-height: 3px !important;
      top: -3px;
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    .ag-pinned-right-header {
      border-left: none;
      overflow: visible;
      .ag-react-container {
        width: 100%;
      }
    }

    .ag-pinned-right-header::after,
    .ag-pinned-right-cols-container > .ag-row::after {
      bottom: -1px;
      box-shadow: inset -10px 0 8px -8px #00000026;
      content: '';
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform: translate(-100%);
      transition: box-shadow 0.3s;
      width: 30px;
    }
    .ag-horizontal-right-spacer {
      border: none !important;
      overflow: hidden;
    }
    .ag-root-wrapper {
      min-height: 300px !important;
    }
    .ag-row {
      align-items: center;
      display: flex;
    }
  }
}

.error {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.initialLoading,
.loading {
  :global {
    .ag-body-viewport {
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .ag-pinned-right-header::after,
    .ag-pinned-right-cols-container > .ag-row::after {
      content: unset;
    }
    .ag-body-horizontal-scroll-viewport {
      display: none;
    }
  }
}

.loadingOverlay {
  cursor: progress;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

.loadingRow {
  :global {
    .ag-selection-checkbox {
      display: none !important;
    }

    .ag-cell {
      padding: 10px;

      &::before {
        animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        background: #f4f4f4; // rgb(224, 224, 224);
        border-radius: 7px;
        content: '';
        display: block;
        height: 20px;
        left: 20px;
        margin-bottom: auto;
        margin-top: auto;
        max-height: 20px;
        position: absolute;
        top: calc(50% - 10px);
        width: calc(100% - 40px);
      }

      > div,
      > span {
        display: none;
      }
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
