$grayLighter: #e5e5e5;
$grayLight: #cbcbcb;
$gray: #929292;
$grayDark: #646464;
$grayDarker: #383838;
$wipAreaBck: #f4f4f4;
$WCMAccent: #d9a81b;
$WCMAccentHover: #ba9803;
$WCMGold: #caa45d;
$white: #ffffff;
$danger: #d72e3d;
$primary: #0032a0;
$success: #008642;

// Dark mode

$DarkBck: #121212;
$DarkTableHeader: #1f1f1f;
