@import 'src/styles/colors';

.paper {
  align-items: center;
  color: $gray !important;
  max-width: 424px !important;
  text-align: center;
}

.title {
  h2 {
    color: $grayDark;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 20px;
  }
}

.content {
  margin-bottom: 16px;
}
