$transition_timing: 0.2s ease;

$breakpoint_desktop-big: 1380px;
$breakpoint_desktop-small: 1024px;
$breakpoint_tablet: 768px;
$breakpoint_mobile: 480px;

$headerHeight: 80px;
$playbarHeight: 130px;
$sidebarWidth: 345px;
$tableMinHeight: 360px;

$sidebarZIndex: 20;
$burgerBtnZIndex: 21;
$dropdownZIndex: 30;
$playBarZIndex: 40;
$mobilPlayBarZIndex: 101;
$playBarPopupZIndex: 41;
$topMenuZIndex: 50;
$mobileBurgerBtnZIndex: 51;
$searchZIndex: 60;
$searchZIndexInMobile: 101;
$logoZIndex: 102;
$markerItemHoverZIndex: 100;
$markerItemZIndex: 100;
$popupsZIndex: 101;
$actionsCellZIndex: 100;
$aboveActionsCellZIndex: 110;
$confirmPopupZIndex: 1400;
$confirmPopupBackdropZIndex: $confirmPopupZIndex - 1;
$bellowConfirmPopupZIndex: $confirmPopupZIndex - 100;
$tooltipPopperZIndex: 9999;
$errorPopupZIndex: 10000;
$loadingZIndex: 100000;
