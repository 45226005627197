@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/vars';

@include keyframe(rotate) {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.songsUpload {
  height: 380px;
  margin-bottom: 50px;
  position: relative;
  transition: height 0.2s ease, margin-bottom 0.2s ease;
  &.collapsed {
    height: 0;
    margin-bottom: unset;
    overflow: hidden;
  }
  .versionsAutocompleteRoot {
    background-color: $white;
    border: 1px solid $grayLight;
    margin-left: 0;
    margin-top: 12px;
    width: 322px;
  }
  .versionsAutocompleteLabel {
    color: $gray;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
}

.expandIcon {
  cursor: pointer;
  height: 20px;
  margin-left: 10px;
  transform: scaleY(-1);
  transition: transform 0.1s ease;
  vertical-align: middle;
  width: 14px;
  z-index: 1;
  &.collapsed {
    transform: scaleY(1);
  }
}

.actionsHeaderCell {
  padding-left: 27px !important;
}

.actionsCell {
  text-align: right;
  width: 100%;
  .iconButton {
    background: none;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    padding: 10px;
    vertical-align: middle;
    &:hover {
      color: $WCMAccentHover;
    }
  }
  .deleteIconButton {
    color: black;
    &:hover {
      color: $grayDarker;
    }
  }
  .pendingIcon {
    @include animation('rotate 1300ms linear infinite');
    box-sizing: content-box;
    padding: 10px;
    vertical-align: middle;
  }
}

.columnHeader {
  color: #383838;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  .requiredIcon {
    color: $danger;
  }
}

.selectCell {
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 13px !important;
  height: 35px;
  width: 100%;
  fieldset {
    border-color: $grayLight !important;
    border-radius: 0;
    border-width: 1px !important;
  }
  &[class~='Mui-error'] {
    fieldset {
      border-color: $danger !important;
    }
  }
  &[class~='Mui-focused'] {
    fieldset {
      border-color: $WCMAccent !important;
    }
  }
  :global {
    .MuiSelect-icon {
      fill: currentColor;
      height: 12px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      path {
        fill: currentColor;
      }
    }
    .MuiSelect-root {
      background-color: white !important;
      padding-bottom: 10px;
      padding-top: 10px;
    }
    .Mui-disabled {
      background-color: $grayLighter !important;
    }
  }
}

.selectList {
  max-height: 160px;
  overflow-y: auto;
  padding-bottom: 0;
  padding-top: 0;
  :global {
    .MuiMenuItem-root {
      font-family: SuisseIntl-Regular-M, sans-serif !important;
      font-size: 13px;
      padding-bottom: 8px;
      padding-top: 8px;

      &:hover,
      &.Mui-focusVisible,
      &.Mui-selected {
        background-color: transparent;
        color: $WCMAccent;
      }
    }
  }
}

.selectPaper {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
  padding: 8px;
}

.dataGrid {
  :global {
    .ag-header-cell:not(.defaultPadding),
    .ag-cell:not(.defaultPadding) {
      padding-left: 14px;
      padding-right: 14px;
    }
    .ag-react-container {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
    }
    .ag-icon.ag-icon-columns {
      @include icon-background('more', 16px);
      background-color: $wipAreaBck;
      transform: rotate(90deg);
      &:before {
        content: '' !important;
      }
    }
    .ag-checkbox-input-wrapper {
      @include icon-background('unchecked-table', 18px);
      box-shadow: none !important;
      height: 18px !important;
      width: 18px !important;
      &:before {
        content: '' !important;
      }
      &::after {
        color: transparent;
      }
      input {
        cursor: pointer;
      }
      &.ag-checked {
        @include icon-background('checked-table', 18px);
        &:before {
          content: '' !important;
        }
        &::after {
          color: transparent;
        }
      }
      &.ag-indeterminate {
        @include icon-background('unchecked-table', 18px);
        &:before {
          content: '' !important;
        }
        &::after {
          color: transparent;
        }
      }
    }
  }
  .disabledCheckbox {
    opacity: 0.5;
    input {
      pointer-events: none;
    }
  }
  .groupedRow {
    background-color: $wipAreaBck !important;
  }
  .groupTag {
    align-items: center;
    background-color: $WCMAccent;
    border-radius: 50px;
    color: white;
    display: inline-flex;
    font-size: 11px;
    height: 20px;
    line-height: 13px;
    margin-left: -10px;
    padding-left: 8px;
    padding-right: 8px;
    button {
      background-color: transparent;
      border: none;
      color: inherit;
      cursor: pointer;
      margin-left: 3px;
      padding: 2px;
    }
  }
  .errorMessage {
    color: $danger;
  }
  .textButton {
    background: none;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    padding: 0;
    vertical-align: middle;
    &:hover {
      color: $WCMAccentHover;
    }
    &:disabled {
      color: $grayLight;
      cursor: not-allowed;
    }
  }
}

.control {
  margin-bottom: 16px;
}

.pwPopup {
  margin-top: 7px;
  .pwPaper {
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    padding: 22px;
    width: 226px;
  }
  .pwSearch {
    position: relative;
  }
  .pwSearchInput {
    background-color: $wipAreaBck;
    border: none;
    border-radius: 50px;
    color: $grayDarker;
    font-family: inherit;
    font-size: inherit;
    line-height: 37px;
    outline: none;
    padding-left: 36px;
    padding-right: 16px;
    width: 100%;
    &::placeholder {
      color: $grayDark;
    }
  }
  .pwSearchIcon {
    height: 13px;
    left: 16px;
    position: absolute;
    top: 12px;
    width: 13px;
  }
  .pwItems {
    height: 170px;
    margin-bottom: 10px;
    margin-top: 10px;
    overflow-y: auto;
  }
  .formControlLabel {
    color: $grayDarker;
    margin: 7px 0;
    width: 100%;
    input[type='radio'] {
      appearance: none;
      background-color: white;
      border: 1px solid;
      border-radius: 50%;
      color: inherit;
      cursor: pointer;
      height: 16px;
      width: 16px;
      &::before {
        border: 1px solid;
        border-radius: 50%;
        content: '';
        display: block;
        height: 4px;
        margin: 4px;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        width: 4px;
      }
      &:checked::before {
        transform: scale(1);
      }
    }
    :global {
      .MuiFormControlLabel-label {
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
        line-height: 16px;
        margin-left: 10px;
      }
    }
  }
  .pwFooter {
    text-align: right;
  }
  .pwAddButton {
    background-color: transparent;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    &:focus,
    &:hover {
      color: $WCMAccentHover;
    }
    &:disabled {
      color: $gray;
      cursor: not-allowed;
    }
  }
}
