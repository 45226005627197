@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute !important;
  z-index: $popupsZIndex !important;
  .dialogPaper {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    max-width: unset;
    overflow: visible;
    padding: 30px 35px 45px;
    width: 330px;
    .inputKeywords {
      border: 1px solid $grayLight;
      min-height: 35px;
      padding: 2px 4px;
      .keywordChip {
        border-color: $WCMAccent;
        & > svg {
          & > path {
            fill: $grayDarker;
          }
        }
      }
      input {
        padding-left: 9px;
      }
    }
    .inputKeywordsLabel {
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      margin-bottom: 6px;
    }
  }
  .dialogBackdrop {
    background-color: transparent;
  }
  .dialogHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
    svg {
      cursor: pointer;
      height: 13px;
      stroke: black;
      stroke-width: 2px;
      width: 13px;
    }
  }
  .sendBtn {
    margin: 20px auto 0;
  }
  .linkLabel {
    cursor: default;
    margin: 16px 0 8px;
    position: relative;
    &.linkLabelWithCopied {
      &::before {
        color: $success;
        content: 'Copied';
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 11px;
        line-height: 13px;
        position: absolute;
        right: 6px;
        top: 7px;
      }
    }
    .linkFormLabel {
      color: $gray;
      cursor: default;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 6px;
      margin-top: 14px;
    }
    .linkFormInput {
      cursor: pointer;
      height: 35px;
      padding-right: 40px;
      width: 100%;
    }
    .linkIcon {
      bottom: 2px;
      top: unset;
    }
  }
  .linkExpiresContainer {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    span,
    button {
      background-color: transparent;
      border: none;
      color: $gray;
      font-family: Suisse SuisseIntl-Regular-M, sans-serif;
      font-size: 11px;
      line-height: 13px;
    }
    button {
      border-bottom: 1px solid $WCMAccent;
      color: $WCMAccent;
      cursor: pointer;
      margin-left: 6px;
    }
  }
}

.datePickerSnackbar {
  bottom: -40px !important;
}
