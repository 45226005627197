@import 'src/styles/colors';
@import 'src/styles/vars';

.confirmSnackbarContainer {
  bottom: 50% !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 50%) !important;
  z-index: $confirmPopupZIndex;
  .confirmSnackbarContent {
    align-items: flex-start;
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    justify-content: center;
    min-height: 172px;
    min-width: unset;
    padding: 36px 0 25px;
    width: 433px;
    &.xs {
      padding: 36px 46px;
      width: 280px;
      & > div {
        padding: 0;
        .confirmSnackbar {
          width: 220px;
          .confirmQuestionText {
            margin-bottom: 0;
          }
          .confirmInfoText {
            white-space: unset;
          }
        }
      }
    }
    & > div {
      padding: 0;
      .confirmSnackbar {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 353px;
        .confirmQuestionText {
          color: $grayDark;
          display: block;
          font-family: SuisseIntl-SemiBold-M, sans-serif;
          font-size: 16px;
          line-height: 16px;
          margin-bottom: 17px;
          text-align: center;
          word-break: break-word;
        }
        .confirmInfoText {
          color: $gray;
          font-family: SuisseIntl-Regular-M, sans-serif;
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 39px;
          max-height: 300px;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: center;
          white-space: pre-wrap;
          word-break: break-word;
        }
        .confirmDoubleBtnGroup {
          justify-content: center;
          white-space: nowrap;
        }
      }
    }
  }
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.7);
  content: '';
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $confirmPopupBackdropZIndex;
}

@media (min-width: $breakpoint_mobile) {
  .confirmSnackbarContainer {
    position: absolute !important;
  }
}

@media (max-width: $breakpoint_mobile - 1) {
  .confirmSnackbarContainer {
    .confirmSnackbarContent {
      width: 260px;
      & > div {
        padding: 0;
        .confirmSnackbar {
          width: 220px;
          .confirmInfoText {
            white-space: unset;
          }
        }
      }
    }
  }
}
