@import 'src/styles/vars';

.datePickerSnackbarContainer {
  background-color: white;
  padding: 0;
  position: absolute !important;
  .datePickerSnackbarContent {
    background-color: white;
    border: none !important;
    border-radius: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    padding: 0 30px;
    & > div {
      padding: 0;
      position: relative;
      & > svg {
        cursor: pointer;
        display: none;
        height: 14px;
        position: absolute;
        right: 21px;
        top: 23px;
        width: 14px;
      }
    }
  }
  &.datePickerSnackbarContainerDisabledPortal {
    bottom: unset;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.singleDayPickerContainer {
    .datePickerSnackbarContent {
      padding: 0 12px;
    }
  }

  &.datePickerSnackbarContainerTop200 {
    top: 200px !important;
  }
}

.singleDayPickerContainerWitchActionBtns {
  .datePickerSnackbarContent {
    & > div {
      & > svg {
        display: block;
      }
    }
  }
}

.datePickerSnackbarContainer.datePickerSnackbarSmallSize {
  bottom: unset;
  top: 100px;
  .datePickerSnackbarContent {
    border-radius: 6px;
    padding: 0 15px;
    & > div {
      & > svg {
        display: block;
      }
    }
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .datePickerSnackbarContainer:not(.singleDayPickerContainer) {
    bottom: unset;
    top: 100px;
    .datePickerSnackbarContent {
      border-radius: 6px;
      padding: 0 15px;
      & > div {
        & > svg {
          display: block;
        }
      }
    }
  }
}

@media (max-width: $breakpoint_desktop-big - 1) {
  .datePickerSnackbarContainerOpenSidebar:not(.singleDayPickerContainer) {
    bottom: unset;
    top: 100px;
    .datePickerSnackbarContent {
      border-radius: 6px;
      padding: 0 15px;
      & > div {
        & > svg {
          display: block;
        }
      }
    }
  }
}

@media (min-width: $breakpoint_desktop-small) {
  .datePickerSnackbarContainer {
    &.datePickerSnackbarContainerOpenSidebar.datePickerSnackbarContainerDisabledPortal {
      left: calc((100% - #{$sidebarWidth}) / 2);
    }
  }
}
