@import 'src/styles/vars';
@import 'src/styles/colors';

.pitches {
  .manualPitchBtn {
    cursor: pointer;
    svg path {
      fill: $WCMAccent;
    }
  }
  .pitchTabs {
    height: calc(100% - 75px);
  }
  .pitchTabsContent {
    height: calc(100% - 101px);
  }
  .moreIcon {
    transform: rotate(90deg);
    path {
      fill: $grayDark;
    }
  }
}

.datePickerSnackbar {
  bottom: unset !important;
}

.dataGridContainer {
  height: 100%;
  padding-right: 0 !important;
  .dataGrid {
    height: 100%;
    .actionsCellContainer {
      align-items: center;
      background-color: $white;
      column-gap: 14px;
      display: flex;
      height: 100%;
      padding-left: 10px;
      svg {
        cursor: pointer;
        height: 14px;
        min-width: 14px;
        width: 14px;
        &:hover {
          path {
            fill: $WCMAccent;
          }
        }
      }
      .moreMenu {
        display: none;
        &:hover {
          svg path {
            fill: $WCMAccent;
          }
        }
      }
      .expandIcon {
        display: none;
      }
    }
    .expandedRow {
      overflow: visible;
      &:before {
        background-color: $white;
        content: '';
        height: 2px;
        position: absolute;
        top: -1px;
        width: 100%;
      }
    }
  }
}

.pitchesViewsCell {
  border: 1px solid $gray;
  border-radius: 12px;
  padding: 2px 10px;
}
.pitchesStatusCell {
  background-color: $gray;
  border-radius: 12px;
  color: $white;
  padding: 2px 10px;
}

.detailRowContainer {
  display: flex;
  height: 36px;
  justify-content: flex-start;
  padding-left: 23px;
  width: 100%;
  svg {
    cursor: pointer;
    &:hover path {
      fill: $WCMAccent;
    }
  }
  .hiddenColItemsContainer {
    column-gap: 40px;
    display: flex;
    .hiddenColItemContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .pitches {
    .pitchTabsContent {
      height: calc(100% - 180px);
      .actionsCellContainer {
        background-color: transparent;
      }
    }
    .actionButton {
      display: none;
    }
    .moreMenu {
      display: inline-flex !important;
    }
  }
}

@media (min-width: $breakpoint_tablet) and (max-width: $breakpoint_desktop-small - 1) {
  .dataGridContainer {
    .dataGrid {
      .actionsCellContainer {
        .expandIcon {
          display: block;
        }
      }
    }
  }
}

@media (min-width: $breakpoint_desktop-small) and (max-width: $breakpoint_desktop-big - 1) {
  .pitches.isOpenSidebar {
    .pitchTabsContent {
      height: calc(100% - 180px);
      .actionsCellContainer {
        background-color: transparent;
      }
    }
    .actionButton {
      display: none;
    }
    .moreMenu {
      display: inline-flex !important;
    }

    .dataGridContainer {
      .dataGrid {
        .actionsCellContainer {
          .expandIcon {
            display: block;
          }
        }
      }
    }
  }
}
