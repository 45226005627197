@import 'src/styles/colors';

.dialog {
  .dialogHeader {
    color: $grayDarker;
    display: flex;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 16px;
    justify-content: space-between;
    letter-spacing: normal;
    line-height: 20px;
    svg {
      cursor: pointer;
    }
  }
  .subTitle {
    color: $gray;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
  .content {
    padding-left: 8px;
    padding-right: 8px;
  }
  .actions {
    padding: 16px 8px;
  }
}
