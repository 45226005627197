@import 'src/styles/colors';

.selectionPanel {
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 13px;
  left: 0;
  line-height: 16px;
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  width: 100%;
  z-index: 10;
  .header {
    align-items: center;
    background-color: $WCMAccent;
    color: white;
    display: flex;
    min-height: 50px;
    padding: 5px;
    position: relative;
    &::before {
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid $WCMAccent;
      content: '';
      height: 0;
      left: 23px;
      position: absolute;
      top: 100%;
      width: 0;
    }
  }
  .selectionText {
    padding-left: 15px;
    padding-right: 5px;
  }
  .closeButton {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    padding: 10px;
  }
  .actions {
    flex-grow: 1;
    padding-left: 30px;
    text-align: right;
  }
  .actionButton {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-family: inherit;
    padding: 10px 15px;
  }
  .content {
    background-color: $wipAreaBck;
    border-bottom: 1px solid $grayLight;
    color: $gray;
    padding: 20px;
    &:empty {
      display: none;
    }
  }
}

.popover {
  margin-top: 7px;
  .paperRoot {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 8px;
  }
  .menuItemButton {
    background-color: transparent;
    border: none;
    color: $grayDarker;
    cursor: pointer;
    font-family: SuisseIntl-Regular-M, sans-serif !important;
    font-size: 13px;
    line-height: 16px;
    padding: 10px 15px;
    width: 100%;
    &:focus,
    &:hover {
      color: $WCMAccent;
    }
  }
}
