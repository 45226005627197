@import 'src/styles/colors';

.dialog {
  .paper {
    border-radius: 6px;
    padding: 36px 32px;
    width: 424px;
  }
  .dialogHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
  }
  .infoText {
    margin-bottom: 30px;
    text-align: center;
  }
  .reasonLabel {
    margin: 0;
    .reasonLabelForm {
      color: $gray;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      margin-bottom: 7px;
    }
    .reasonInput {
      height: 35px;
      width: 100%;
    }
  }
  .doubleBtnGroup {
    justify-content: center;
    margin-top: 30px;
  }
}
