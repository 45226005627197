@import 'src/styles/vars';
@import 'src/styles/colors';

.loadingDataAnimation {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: $loadingZIndex;
  & > div {
    background-color: $white;
    border-radius: 50%;
    height: 240px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 240px;
  }
  & > svg {
    height: 400px !important;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 400px !important;
    z-index: 2;
  }
}
