@font-face {
  font-family: 'Plaak';
  src: url('../assets/fonts/Plaak/Plaak - 56-Ney-Heavy-205TF.woff2') format('woff2');
  src: url('../assets/fonts/Plaak/Plaak - 56-Ney-Heavy-205TF.woff') format('woff');
}

@font-face {
  font-family: 'SuisseIntl-SemiBold-M';
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.svg') format('svg');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.woff') format('woff2');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-SemiBold-WebM.woff2') format('woff2');
}

@font-face {
  font-family: 'SuisseIntl-Regular-M';
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-Regular-WebM.svg') format('svg');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-Regular-WebM.woff') format('woff');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-Regular-WebM.woff2') format('woff2');
}

@font-face {
  font-family: 'SuisseIntl-RegularItalic-M';
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-RegularItalic-WebM.svg') format('svg');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-RegularItalic-WebM.woff') format('woff');
  src: url('../assets/fonts/SuisseIntl/SuisseIntl-RegularItalic-WebM.woff2') format('woff2');
}
