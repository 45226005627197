@import 'src/styles/colors';

.paperRoot {
  align-items: flex-start;
  background-color: $danger !important;
  box-shadow: none;
  color: white !important;
  display: flex;
  margin-top: 85px;
  padding: 20px;
  width: 295px;
}

.content {
  flex-grow: 1;
}

.title {
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.closeButton {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.description {
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  margin-top: 5px;
}
