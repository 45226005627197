@import 'src/styles/colors';
@import 'src/styles/vars';

.formRoot {
  align-items: flex-start !important;
  justify-content: space-between;
  margin: 13px 0 0 0 !important;
  width: 100%;
  .label {
    color: $gray;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .inputKeywords {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    max-height: 98px;
    min-height: 40px;
    overflow-y: auto;
    padding: 6px 8px;
    width: 100%;
    .keywordChip {
      border-color: $grayLight;
      height: 20px;
      margin: 4px 2px;
      max-width: calc(100% - 4px);
      & > svg {
        margin-right: 8px;
        width: 10px;
        & > path {
          fill: $grayLight;
        }
      }
      & > .chipLabel {
        padding-left: 10px;
        padding-right: 11px;
      }
    }
    input {
      border: none;
      color: $grayDarker;
      flex-grow: 1;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      height: 16px;
      min-width: 40px;
      outline: none;
      padding-left: 2px;
      width: 0;
      &::placeholder {
        color: $grayDark;
        font-style: italic;
      }
    }
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .formRoot {
    margin: 7px 0 0 0 !important;
  }
}
