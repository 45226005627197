@import 'src/styles/colors';
@import 'src/styles/vars';

.control {
  align-items: center;
  background-color: white;
  border: 1px solid $grayLight;
  color: $grayDarker;
  display: flex;
  position: relative;
  width: 100%;
}

.input {
  -moz-appearance: textfield; // Hide arrows for number type in Firefox
  background-color: transparent;
  border: none;
  color: inherit;
  display: block;
  flex-grow: 1;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 33px;
  outline: none;
  padding: 0 15px;
  width: 100%;
  /* Hide arrows for number type in Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: $gray;
    font-style: italic;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

.disabled {
  background-color: $grayLighter;
  cursor: not-allowed;
}

.label {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  &:empty {
    display: none;
  }
}

.labelPlacementStart {
  margin-left: 0 !important;
  margin-right: 0 !important;
  :global {
    .MuiFormControlLabel-label {
      width: 100px;
    }
  }
}

.labelPlacementTop {
  align-items: flex-start !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  :global {
    .MuiFormControlLabel-label {
      margin-bottom: 8px;
    }
  }
}

.requiredIcon {
  background-color: currentColor;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.iconContainer {
  align-items: center;
  color: currentColor;
  display: flex;
  margin-right: 10px;
  > * {
    margin-left: 10px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.tooltipIcon {
  align-items: center;
  display: flex;
  height: 33px;
}

.errorBorder {
  border-bottom-color: $danger;
}

.errorColor {
  color: $danger;
}

.validBorder {
  border-bottom-color: $success;
}

.validColor {
  color: $success;
}

.focused {
  border-bottom-color: black;
}

.tooltipPopper {
  z-index: $tooltipPopperZIndex !important;
}

.tooltip {
  background-color: $danger !important;
  border-radius: 0 !important;
  color: white !important;
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 11px !important;
  line-height: 13px !important;
  min-width: 120px;
  padding: 10px !important;
}

.tooltipArrow {
  color: $danger !important;
}

.inputWithIcon {
  margin-right: 5px;
  padding-right: 5px;
}

.error {
  border-color: $danger;
  color: $danger;
  &::placeholder {
    color: $danger;
  }
}

.textFieldIcon {
  color: $grayDark;
  font-size: 11px;
  line-height: 35px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 0;
}

.textFieldWithIcon {
  padding-right: 30px;
}
