.progressBar {
  display: flex;
  position: relative;
  .curcle {
    color: #0032a0;
    height: 100%;
    transform: rotate(-90deg);
    width: 100%;
  }
  .curcleProgress {
    fill: none;
    stroke: currentColor;
    transition: stroke-dashoffset 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .rect {
    background-color: #c4c4c4;
    height: 5px;
    overflow: hidden;
  }
  .rectProgress {
    background-color: #0032a0;
    height: 100%;
    transition: all 0.5s;
  }
  .content {
    display: flex;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
