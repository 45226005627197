@import 'src/styles/colors';

.textWithFade {
  display: block;
  white-space: nowrap;
  &:not(.dark) {
    color: black;
  }
  &.dark {
    color: $white;
  }
  &.overflowing {
    &:not(.dark) {
      background: linear-gradient(to right, black 85%, $white);
    }
    &.dark {
      background: linear-gradient(to right, $white 85%, black);
    }
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
  }
}
