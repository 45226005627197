@import 'src/styles/colors';
@import 'src/styles/vars';

.successSnackbarContainer {
  bottom: 50% !important;
  left: 50% !important;
  right: auto !important;
  transform: translate(-50%, 50%) !important;
  z-index: $bellowConfirmPopupZIndex !important;
  .successSnackbarContent {
    background-color: $white;
    border-radius: 6px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    height: 80px;
    justify-content: center;
    min-width: unset;
    width: 215px;
    .successSnackbar {
      text-align: center;
      .successText {
        color: $grayDark;
        display: block;
        font-family: SuisseIntl-SemiBold-M, sans-serif;
        font-size: 13px;
        line-height: 16px;
        margin-top: 3px;
      }
    }
  }
}

@media (min-width: $breakpoint_mobile) {
  .confirmSnackbarContainer {
    position: absolute !important;
  }
}
