@import 'src/styles/colors';
@import 'src/styles/vars';

.pitchDetails {
  :global {
    .MuiSnackbar-root {
      position: fixed !important;
    }

    .ag-root-wrapper {
      min-height: 170px !important;
    }
  }

  &.pitchDetailsOpenSidebar {
    :global {
      .MuiSnackbar-root {
        left: calc((100% - #{$sidebarWidth}) / 2);
      }
    }
  }
  .playIcon {
    svg {
      cursor: pointer;
      vertical-align: middle;
    }
  }
  .arrowBackIcon {
    cursor: pointer;
    margin-bottom: 28px;
    margin-left: 4px;
  }
  .pitchHeader {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }
  .pitchTitleEditTooltip {
    display: grid;
  }
  .pitchTitle {
    color: $grayDarker;
    display: block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    & ~ svg {
      top: -3px;
    }
  }
  .pitchHeaderCols {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 70px;
    margin-top: 38px;
    row-gap: 24px;
  }
  .infoText {
    color: $grayDarker;
    display: unset;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 14px;
    line-height: 18px;
  }
  .grayTitle {
    color: $gray;
    display: block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
  .sectionContainer {
    margin-top: 38px;
    .sectionTitle {
      color: $grayDarker;
      display: block;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 22px;
    }
    .pitchSongsDataGrid {
      :global {
        .ag-root-wrapper {
          min-height: unset !important;
        }
      }
    }
  }
  .pitchEmail {
    background-color: $wipAreaBck;
    border-radius: 6px;
    padding: 20px;
    width: 534px;
    a {
      text-decoration: none;
      word-break: break-word;
    }
    & > span {
      display: block;
      &:not(:last-of-type) {
        margin-bottom: 12px;
      }
      &.requestedTitle {
        margin-bottom: 32px;
        word-break: break-word;
      }
      &.linkInfo {
        margin-bottom: 30px;
        word-break: break-all;
        & > span {
          color: $WCMAccent;
          cursor: pointer;
        }
      }
    }
  }
}

.titleVersionWrapper {
  max-width: fit-content;
}

.versionText {
  align-items: center;
  color: $gray;
  display: flex;
  margin-right: -10px;
}

.inputTextLabelRoot {
  margin: 0 !important;
  & > input {
    height: 35px !important;
    width: 162px !important;
    &[type='number'] {
      padding: 0 5px;
      text-align: center;
      width: 46px;
    }
  }
}

.autocompleteLabelRoot {
  margin: 0 !important;
  :global {
    .MuiAutocomplete-root {
      height: 35px;
      margin-left: 0;
      width: 100px;
    }
  }
}

.resultCell {
  align-items: center;
  display: flex;
}

.songTitleCell {
  align-items: center;
  display: flex;
  :global {
    .ag-react-container {
      width: 100%;
    }
  }
  .songTitleContainerWithWriter,
  .songTitleContainerWithVersion {
    column-gap: 12px;
    display: flex;
    min-width: 0;
    width: fit-content;
  }
}

.addSongBtn {
  margin-top: 15px;
  svg path {
    fill: $WCMAccent;
  }
}

.actionsCellContainer {
  align-items: center;
  column-gap: 14px;
  display: flex;
  height: 100%;
  padding-left: 8px;
  .expandIcon {
    display: none;
  }
  svg {
    cursor: pointer;
    height: 14px;
    width: 14px;
    &:hover {
      path {
        fill: $WCMAccent;
      }
    }
  }
}

.detailRowContainer {
  display: flex;
  height: 36px;
  justify-content: flex-start;
  padding: 0 20px 0 53px;
  width: 100%;
  svg {
    cursor: pointer;
    &:hover path {
      fill: $WCMAccent;
    }
  }
  .hiddenColItemsContainer {
    column-gap: 40px;
    display: flex;
    .hiddenColItemContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}

@media (min-width: $breakpoint_tablet) and (max-width: $breakpoint_desktop-small - 1) {
  .actionsCellContainer {
    .expandIcon {
      display: block;
    }
  }
}

@media (min-width: $breakpoint_desktop-small) and (max-width: $breakpoint_desktop-big - 1) {
  .actionsCellContainer {
    .expandIconIsOpenSidebar {
      display: block;
    }
  }
}

.showOnlyInMobile {
  display: none;
}

.expandedRow {
  overflow: visible;
  &:before {
    background-color: $white;
    content: '';
    height: 2px;
    position: absolute;
    top: -1px;
    width: 100%;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .hideInMobile {
    display: none !important;
  }
  .showOnlyInMobile {
    display: inline-block;
  }
  .songTitleContainerWithWriter {
    display: flex !important;
    flex-direction: column;
    gap: 6px;
    width: calc(100% - 33px);
  }
  .songTitleContainerWithVersion {
    align-items: center;
    display: flex !important;
    justify-content: space-between;
    width: 100%;
  }
}
