@import 'src/styles/colors';

.actionsWrapper {
  align-items: center;
  display: flex;
  height: 100%;
}

.actions {
  align-items: center;
  background-color: $white;
  display: flex;
  height: 100%;
  transition: background-color 0.1s;
  z-index: 1;
}

.button {
  margin-left: 10px;
  padding: 5px !important;
  &:first-child {
    margin-left: 0;
  }
  svg {
    vertical-align: middle;
  }
}

.visibleActions {
  background-color: transparent;
  display: flex;
}
