@import 'src/styles/colors';

.header {
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.title {
  color: $grayDark;
  flex-grow: 1;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.closeButton {
  padding: 0;
}

.actions {
  padding: 24px !important;
}

.submitButton {
  margin-left: 16px !important;
}
