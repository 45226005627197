@import 'src/styles/colors';
@import 'src/styles/vars';

.wrapper {
  height: 100%;
  padding: 42px 20px 0 30px;
  position: relative;
  width: 100%;
}

.tabletWrapper {
  height: 100%;
  padding: 42px 30px 0;
  position: relative;
  width: 100%;
}

.navItem {
  color: $grayDarker;
  cursor: pointer;
  display: flex;
  font-family: Plaak, sans-serif;
  font-size: 11px;
  font-weight: 300;
  justify-content: center;
  line-height: 13px;
  padding: 10px 20px;
  position: relative;
  &:before {
    background-color: $WCMAccent;
    bottom: -7px;
    content: '';
    height: 1px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    transition: background-color $transition_timing, width $transition_timing;
    width: 0;
  }
  &:hover {
    color: $WCMAccent;
  }
}
.activeNavItem {
  color: $WCMAccent;

  &:before {
    background-color: $WCMAccent;
    width: calc(100% - 40px);
  }
}

// SuisseIntl Gray Lighter

.f16h20SuisseREG_grayLighter {
  color: $grayLighter;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.f20h24SuisseREG_grayLighter {
  color: $grayLighter;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 20px;
  line-height: 24px;
}

// SuisseIntl Gray Light

.f16h20SuisseREG_grayLight {
  color: $grayLight;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

// SuisseIntl Gray

.f11h13SuisseREG_gray {
  color: $gray;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

.f11h13SuisseSB_gray {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

.f13h16SuisseSB_gray {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

// SuisseIntl Gray Dark

.f11h13SuisseREG_grayDark {
  color: $grayDark;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

.f13h16SuisseREG_gray {
  color: $gray;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.f13h16SuisseSB_grayDark {
  color: $grayDark;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

// SuisseIntl Gray Darker

.f11h13SuisseREG_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
  & > a {
    color: $WCMAccent;
  }
}

.f11h13SuisseSB_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

.f13h16SuisseREG_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  & > a {
    color: $WCMAccent;
  }
}

.f14h18SuisseREG_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 14px;
  line-height: 18px;
}

.f13h16SuisseSB_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.f13h20SuisseREG_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 20px;
}

.f16h20SuisseSB_grayDarker {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

// SuisseIntl Primary

.f13h16SuisseSB_primary {
  color: $primary;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

// SuisseIntl Danger

.f13h16SuisseREG_danger {
  color: $danger;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.f16h16SuisseREG_danger {
  color: $danger;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.f13h16SuisseSB_danger {
  color: $danger;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

// SuisseIntl Black

.f9h11SuisseREG_black {
  color: black;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 9px;
  line-height: 11px;
}

.f11h13SuisseREG_black {
  color: black;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

.f13h16SuisseREG_black {
  color: black;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.f13h20SuisseREG_black {
  color: black;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 20px;
}

.f13h16SuisseSB_black {
  color: black;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.f16h20SuisseSB_black {
  color: black;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.f16h20SuisseREG_black {
  color: black;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.f20h24SuisseSB_black {
  color: black;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

// SuisseIntl White

.f11h13SuisseREG_white {
  color: $white;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

.f13h16SuisseREG_white {
  color: $white;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
}

.f16h18SuisseREG_white {
  color: $white;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 16px;
  line-height: 18px;
}

.f13h16SuisseSB_white {
  color: $white;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.f11h13SuisseSB_white {
  color: $white;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

// SuisseIntl Gold

.f11h13SuisseREG_gold {
  color: $WCMAccent;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
}

.f11h13SuisseSB_gold {
  color: $WCMAccent;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 13px;
}

//Plaak White

.f20h28PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}

.f13h22PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}

.f13h16PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}

.f22h22PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
}

.f26h30PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
}

.f28h34PlaakB_white {
  color: $white;
  font-family: Plaak, sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
}

//Plaak Gray Darker

.f16h19PlaakB_grayDarker {
  color: $grayDarker;
  font-family: Plaak, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
}

.songPlayingTitle {
  color: $WCMAccent !important;
}

.textEllipsis {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}
