@import 'src/styles/colors';

.paper {
  align-items: center;
  background-color: #f0f0f0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  height: 459px;
  padding: 67px 0 33px 0;
  position: relative;
  width: 263px;
  .closeBtn {
    cursor: pointer;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
  }
  h3 {
    color: #000000;
    font-family: Plaak, sans-serif;
    font-size: 16px;
    margin-bottom: 9px;
  }
  span {
    color: #000000;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 25px;
    text-align: center;
    width: 204px;
  }
  img {
    margin-bottom: 24px;
    width: 100px;
  }
  .openBtn {
    background-color: $WCMAccent;
    border: none;
    color: white;
    cursor: pointer;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 14px;
    padding: 18px 15px;
    &:hover {
      background-color: $WCMAccentHover;
    }
  }
}

.dialogBackdrop {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
