@import 'src/styles/colors';
@import 'src/styles/vars';

.tabCard {
  background-color: $wipAreaBck;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px 40px;
  transition: background-color $transition_timing;
  &:hover {
    background-color: $grayLighter;
  }
  .tabTitle,
  .tabValue {
    display: block;
    font-family: SuisseIntl-Regular-M, sans-serif;
    text-align: center;
    transition: color $transition_timing;
  }
  .tabTitle {
    color: $gray;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .tabValue {
    color: $grayDarker;
    font-size: 28px;
    line-height: 24px;
  }
  &.activeTabCard {
    background-color: $WCMAccent;
    .tabTitle,
    .tabValue {
      color: $white;
    }
  }
}

@media (max-width: 1130px) {
  .tabCard {
    padding: 20px 30px;
  }
}
