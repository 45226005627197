@import 'src/styles/colors';
@import 'src/styles/vars';

.adminControls {
  .arrowRowIcon {
    cursor: pointer;
    path {
      fill: $grayDark;
    }
  }
  .tabs {
    height: calc(100% - 75px);
    .tabContent {
      height: calc(100% - 63px);
    }
  }
  .tabsContainer {
    display: flex;
    grid-gap: 28px;
    margin-bottom: 25px;
  }
  .dataGridContainer {
    height: calc(100% - 90px - 25px - 20px - 14px);
    min-height: $tableMinHeight;
    position: relative;
    width: 100%;
    .dataGrid {
      height: 100%;
      width: 100%;
      .actionsHeader {
        justify-content: flex-start;
        padding: 0;
        .activeIcon {
          path {
            fill: $WCMAccent;
          }
        }
        .moreIconContainer {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding-left: 28px;
          & > span {
            font-family: SuisseIntl-SemiBold-M, sans-serif;
            font-size: 13px;
            font-weight: 500;
            margin-top: 2px;
          }
          .moreHeaderIcon {
            cursor: pointer;
            height: 56px;
            padding: 18px;
            transform: rotate(90deg);
            width: 50px;
          }
        }
      }
      .headerCell {
        color: $grayDarker;
        font-family: SuisseIntl-SemiBold-M, sans-serif;
        font-size: 13px;
        font-weight: 500;
      }
      .statusesHeaderCell {
        :global {
          .ag-header-cell-text {
            width: 73px;
          }
        }
      }
      .indexCell {
        align-items: center;
        color: $WCMAccent;
        display: flex;
        font-family: SuisseIntl-Regular-M, sans-serif;
      }
      .userCell {
        align-items: center;
        display: flex;
        .usersCellContainer {
          display: inline-block;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          white-space: nowrap;
          span {
            &:first-child {
              color: $grayDarker;
              font-family: SuisseIntl-SemiBold-M, sans-serif;
              line-height: 16px;
            }
            &:last-child {
              color: $gray;
              font-family: SuisseIntl-Regular-M, sans-serif;
              line-height: 14px;
              margin-left: 12px;
            }
          }
        }
      }
      .detailRowContainer {
        align-items: flex-end;
        display: flex;
        height: 36px;
        justify-content: flex-start;
        margin-top: 10px;
        padding: 0 20px 0 53px;
        width: 100%;
        svg {
          cursor: pointer;
          &:hover path {
            fill: $WCMAccent;
          }
        }
        .hiddenColItemsContainer {
          column-gap: 40px;
          display: flex;
          .hiddenColItemContainer {
            display: flex;
            flex-direction: column;
            font-size: 11px;
            justify-content: space-between;
            .hiddenColTitle {
              color: $gray;
              font-family: SuisseIntl-SemiBold-M, sans-serif;
              line-height: 13px;
            }
            .hiddenColValue {
              color: $grayDarker;
              font-family: SuisseIntl-Regular-M, sans-serif;
              line-height: 16px;
            }
          }
        }
        .expandedActionsCell {
          align-items: center;
          display: flex;
          grid-gap: 24px;
          height: calc(100% - 10px);
          left: 40px;
          position: relative;
        }
      }
      .cellContainer,
      .statusesCellRenderer {
        align-items: center;
        color: $grayDarker;
        display: flex;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
      }
      .actionsCellContainer {
        align-items: center;
        display: flex;
        overflow: visible;
        padding-left: 28px;
        padding-right: 0;
        z-index: 2;
        .actionsCell {
          display: flex;
          & svg {
            cursor: pointer;
            height: 14px;
            width: 14px;
            &:not(:last-child) {
              margin-right: 24px;
            }
            &:hover path {
              fill: $WCMAccent;
            }
          }
        }
      }
      .rowContainer {
        &.expandedRow {
          background-color: $white;
          overflow: visible;
          &:before {
            background-color: $white;
            content: '';
            height: 2px;
            position: absolute;
            top: -1px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .adminControls {
    .tabsContainer {
      grid-gap: 18px !important;
    }
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .statusesHeaderCell {
    :global {
      .ag-header-cell-label {
        justify-content: flex-end !important;
      }
    }
  }
  .rowContainer {
    .statusesCellRenderer {
      justify-content: flex-end !important;
    }
  }
  .moreHeaderIcon {
    display: none;
  }
}

@media (min-width: $breakpoint_desktop-small) and (max-width: $breakpoint_desktop-big - 1) {
  .statusesHeaderCell {
    :global {
      .ag-header-cell-label {
        justify-content: flex-end !important;
      }
    }
  }
  .rowContainer {
    .statusesCellRenderer {
      justify-content: flex-end !important;
    }
  }
  .moreHeaderIcon {
    display: none;
  }
  .iconsCell {
    .statusesHeaderCell {
      :global {
        .ag-header-cell-label {
          justify-content: center !important;
        }
      }
    }
    .rowContainer {
      .statusesCellRenderer {
        justify-content: center !important;
      }
    }
    .arrowRowIcon {
      display: none;
    }
    .moreHeaderIcon {
      display: block;
    }
  }
}

@media (min-width: $breakpoint_desktop-big) {
  .arrowRowIcon {
    display: none;
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .adminControls {
    .dataGrid.iconsCell {
      .rowContainer {
        .actionsCell {
          display: none !important;
        }
      }
      .actionsHeader {
        justify-content: center;
      }
    }
  }
}

@media (max-width: $breakpoint_desktop-big - 1) {
  .adminControls {
    .dataGrid:not(.iconsCell) {
      .rowContainer {
        .actionsCell {
          display: none !important;
        }
      }
      .actionsHeader {
        justify-content: center;
      }
    }
  }
}
