@import 'src/styles/colors';
@import 'src/styles/vars';

.songsDropzone {
  align-items: center;
  border: 1px dashed #cbcbcb;
  display: flex;
  height: 54px;
  justify-content: center;
  margin-top: 31px;
  width: 100%;
  .songsDropzoneContent {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    svg {
      height: 25px;
      margin-right: 22px;
      width: 25px;
    }
    span {
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
    }
    .songsDropText {
      color: $gray;
      margin: 0 34px;
    }
    .songsBrowse {
      color: $WCMAccent;
      margin-left: 24px;
    }
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .songsDropzone {
    display: none;
  }
}
