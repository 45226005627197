@import 'src/styles/colors';

.textField {
  position: relative;
  width: 100%;
}

.input {
  -moz-appearance: textfield; // Hide arrows for number type in Firefox
  background-color: white;
  border: 1px solid $grayLight;
  color: $grayDarker;
  display: block;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 35px;
  outline: none;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  /* Hide arrows for number type in Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: $gray;
    font-style: italic;
  }
  &:focus {
    border-color: $WCMAccent;
    color: $grayDarker;
    &::placeholder {
      color: $gray;
    }
  }
  &:disabled {
    background-color: $grayLighter;
  }
}

.error {
  border-color: $danger;
  color: $danger;
  &::placeholder {
    color: $danger;
  }
}

.label {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  &:empty {
    display: none;
  }
}

.labelPlacementTop {
  align-items: flex-start !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  :global {
    .MuiFormControlLabel-label {
      margin-bottom: 8px;
    }
  }
}

.required {
  color: $danger;
  margin-left: 4px;
}

.textFieldIcon {
  color: $grayDark;
  font-size: 11px;
  line-height: 35px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 0;
}

.textFieldWithIcon {
  padding-right: 30px;
}
