@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute !important;
  z-index: $popupsZIndex !important;
  .dialogPaper {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    max-height: unset;
    padding: 40px;
    position: fixed;
    width: 565px;
    &.whiteBackground {
      &:before {
        background-color: rgba(255, 255, 255, 0.9);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: $popupsZIndex;
      }
    }
    .editHeader {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .editHeaderTitle {
        color: $grayDarker;
        font-family: SuisseIntl-SemiBold-M, sans-serif;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .editFooter {
      align-items: center;
      display: flex;
      height: 35px;
      justify-content: space-between;
      margin-top: 26px;
      .iconsContainer {
        align-items: center;
        display: flex;
        justify-content: center;
        svg {
          cursor: pointer;
          &:not(:last-of-type) {
            margin-right: 22px;
          }
          &:hover path {
            fill: $WCMAccent;
          }
        }
      }
      .editRestoreUser {
        color: $WCMAccent;
        cursor: pointer;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
        line-height: 16px;
        user-select: none;
      }
    }
    .editItemsContainer {
      column-gap: 44px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      height: 251px;
      margin-top: 46px;
      row-gap: 21px;
      .editItemContainer {
        display: flex;
        flex-direction: column;
        font-size: 13px;
        height: 47px;
        justify-content: space-between;
        line-height: 16px;
        width: 220px;
        &.withMarginBottom {
          margin-bottom: 10px;
        }
        .editItemTitle {
          color: $gray;
          font-family: SuisseIntl-SemiBold-M, sans-serif;
          user-select: none;
          & > span {
            color: red;
          }
        }
        .editItemDataKey {
          align-items: center;
          color: $grayDarker;
          display: flex;
          font-family: SuisseIntl-Regular-M, sans-serif;
          height: 35px;
          & > span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .formLabel {
          &.disabled {
            color: $grayLight;
          }
        }
        & > label {
          margin-right: 0;
        }
        .autocompleteRoot {
          border: 1px solid $grayLight;
          margin-left: 0;
          transition: width $transition_timing, height $transition_timing !important;
          width: 220px;
          .autocompleteInputRoot {
            cursor: default !important;
            height: 33px;
            margin-top: 0;
            padding: 3px 15px 3px 15px;
            &:before,
            &:after {
              display: none;
            }
            .autocompleteInput {
              color: $grayDarker;
              font-family: SuisseIntl-Regular-M, sans-serif;
              font-size: 13px;
              line-height: 16px;
            }
            .autocompleteEndAdornment {
              align-items: center;
              display: flex;
              height: 10px;
              justify-content: center;
              right: 14px;
              top: 10px;
              svg {
                height: 11px;
                path {
                  fill: $gray;
                }
              }
            }
          }
          &.autocompleteRootDisabled {
            background-color: $grayLighter;
          }
        }
      }
    }
  }
  .dialogBackdrop {
    background-color: transparent;
  }
}

.autocompletePopper {
  top: 2px;
  width: 218px;
  .autocompletePaper {
    border-radius: unset;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
    margin: 0;
    padding: 25px 8px 25px 22px;
  }
}

.asyncAutocomplete {
  width: 220px;
}
