@import 'src/styles/colors';
@import 'src/styles/vars';

.pageHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 56px;
  padding-left: 3px;
  position: relative;
  .topBtn {
    left: 3px;
    position: absolute;
    top: 0;
  }
  .pageTitle {
    color: $grayDarker;
    font-family: Plaak, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
  }
  &.topBtnExists {
    padding-left: 0;
    padding-top: 48px;
  }
  &.smallMargin {
    margin-bottom: 28px;
  }
  &.mediumMargin {
    margin-bottom: 40px;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .pageHeader:not(.hasMarginFromOutside) {
    margin-bottom: 20px !important;
  }
}
