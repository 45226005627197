.actionButton {
  background-color: transparent;
  border: none;
  color: #646464;
  cursor: pointer;
  display: block;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 30px;

  &:focus,
  &:hover {
    color: #383838;
  }
}
