@import 'src/styles/colors';

.container {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  svg {
    margin-bottom: 12px;
  }
}
