@import 'src/styles/colors';
@import 'src/styles/mixins';

.dataGridWithSelectionPanel {
  position: relative;
}

.dataGrid {
  :global {
    .settings {
      background-color: transparent;
    }
    .panel {
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25) !important;
      padding: 30px 0 20px 30px;
      &::after {
        content: '';
        height: 30px;
        position: absolute;
        width: 1px;
      }
      .title {
        color: $gray;
        font-size: 9px;
        font-weight: normal;
        line-height: 11px;
        text-transform: uppercase;
      }
    }
    .ag-row-level-1 {
      background-color: white;
      margin-top: -1px;
    }
    .ag-header-cell,
    .ag-cell {
      line-height: 20px;
      padding-left: 14px;
      padding-right: 14px;
    }
    .ag-cell-value {
      align-items: center;
      display: inline-flex;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:empty::after {
        content: '\2014'; // Prevent encoding issue for char '—'
      }
    }
    .ag-root-wrapper {
      min-height: 250px !important;
    }
    .ag-react-container {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      &:empty::after {
        content: '\2014'; // Prevent encoding issue for char '—'
      }
    }
    .ag-pinned-right-cols-container {
      .ag-cell {
        padding: 0 !important;
      }
      .ag-cell-value:empty::after {
        display: none;
      }
      .ag-react-container {
        justify-content: flex-start;
        padding-left: 10px;
      }
      & > .ag-row {
        background-color: $white !important;
      }
    }
    .ag-root[class*='loading'] .ag-cell-value,
    .ag-root[class*='loading'] .ag-react-container,
    *[ref='eCellValue'] {
      &:empty::after {
        display: none;
      }
    }
    .ag-selection-checkbox {
      margin-right: 0 !important;
    }
    .ag-checkbox {
      margin-left: 10px;
    }
    .ag-icon.ag-icon-columns {
      @include icon-background('more', 16px);
      background-color: $wipAreaBck;
      transform: rotate(90deg);
      &:before {
        content: '' !important;
      }
    }
    .ag-checkbox-input-wrapper {
      @include icon-background('unchecked-table', 18px);
      box-shadow: none !important;
      height: 18px !important;
      width: 18px !important;
      &:before {
        content: '' !important;
      }
      &::after {
        color: transparent;
      }
      input {
        cursor: pointer;
      }
      &.ag-checked {
        @include icon-background('checked-table', 18px);
        &:before {
          content: '' !important;
        }
        &::after {
          color: transparent;
        }
      }
      &.ag-indeterminate {
        @include icon-background('unchecked-table', 18px);
        &:before {
          content: '' !important;
        }
        &::after {
          color: transparent;
        }
      }
    }
    .overflow-visible {
      overflow: visible;
    }
    .ag-row-hover .actions-renderer {
      display: flex;
    }
  }
}
