@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  inset: unset !important;
  left: 0 !important;
  top: 0 !important;
  z-index: $searchZIndex !important;
  .dialogPaper {
    box-shadow: none;
  }
  .dialogBackdrop {
    background-color: transparent;
  }

  .search {
    background-color: $white;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 43px 46px 25px 45px;
    width: 100vw;
    .closeBtn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      height: 28px;
      margin-left: auto;
      width: 28px;
    }
    .searchHeader {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      .searchInputBorder {
        border-left: 1px solid;
        padding-left: 20px;
        width: 100%;
      }
      .searchQuery {
        border: none;
        color: $grayDarker;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 32px;
        line-height: 38px;
        outline: none;
        width: 100%;
        &::placeholder {
          color: $grayDark;
        }
      }
    }
    .filtersContainer {
      display: flex;
      grid-gap: 68px;
      margin-top: 70px;
      max-height: 324px;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 30px;
      position: relative;
      .autocompleteContainer {
        display: flex;
        flex-direction: column;
      }
      .switchBtnsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 61px 0 58px;
        position: relative;
        transition: padding $transition_timing;
        &.isNotFullSwitchBtnsContainer {
          justify-content: flex-start;
          row-gap: 15px;
        }
        .switchBtnRoot {
          width: 255px;
        }
      }
      .songTitlesWritersContainer {
        display: flex;
        flex-direction: column;
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
        justify-content: space-between;
        line-height: 16px;
        padding: 7px 0 11px 0;
        position: relative;
        &.songTitlesContainer {
          width: 285px;
        }
        &.writersContainer {
          width: 210px;
          &:before {
            background-color: $grayLight;
            content: '';
            height: 100%;
            left: -26px;
            position: absolute;
            width: 1px;
          }
        }
        .songTitlesWriters {
          color: $grayDarker;
        }
        .shortResultContainer {
          display: flex;
          flex-direction: column;
          grid-gap: 12px;
        }
        .viewAll {
          color: $grayDark;
          cursor: pointer;
          text-decoration-line: underline;
          white-space: nowrap;
        }
        p {
          color: $grayDarker;
          font-size: 11px;
          line-height: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  @media (max-width: $breakpoint_desktop-big) {
    .search {
      .filtersContainer {
        grid-gap: 24px;
        .switchBtnsContainer {
          padding: 0 8px 0 10px;
        }
      }
    }
  }
  @media (max-width: $breakpoint_desktop-small) {
    .search {
      padding: 43px 20px 25px 19px;
      .filtersContainer {
        column-gap: 40px;
        flex-wrap: wrap;
        margin-top: 47px;
        max-height: calc(100vh - 292px);
        overflow-x: hidden;
        overflow-y: auto;
        row-gap: 100px;
        .songTitlesWritersContainer {
          padding-top: 0;
          row-gap: 20px;
          width: calc(50% - 39px) !important;
          &.songTitlesContainer {
            &:before {
              background-color: $grayLight;
              content: '';
              height: 1px;
              left: -15px;
              position: absolute;
              top: -35px;
              width: calc(100vw - 85px);
            }
          }
        }
        .switchBtnsContainer {
          padding: 0 0 0 24px;
          &:before {
            background-color: $grayLight;
            content: '';
            height: 100%;
            left: 0;
            position: absolute;
            width: 1px;
          }
        }
      }
    }
  }
}

// Add Tags Popup Styles

.addTagsArtistsAutocompleteLabel {
  justify-content: space-between;
  margin-left: 0;
  .artistsAutocomplete {
    .artistsInputBaseClassName {
      min-height: 32px;
      input {
        height: 22px !important;
        margin: 2px 0;
      }
      fieldset {
        border-color: $grayLight !important;
      }
    }
  }
}

// Search Popup Styles

.searchArtistsAutocompleteLabel {
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 0 !important;
  & > span {
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif !important;
  }
  .artistsAutocomplete {
    width: 230px;
    .artistsInputBaseClassName {
      min-height: 30px;
      input {
        height: 22px !important;
        margin: 2px 0;
      }
      fieldset {
        border-color: $grayLight !important;
      }
    }
  }
}

@media (max-width: $breakpoint_desktop-big) and (min-width: $breakpoint_desktop-small + 1) {
  .searchArtistsAutocompleteLabel > .artistsAutocomplete {
    width: 187px;
  }
}

@media (max-width: $breakpoint_desktop-small) and (min-width: $breakpoint_tablet + 1) {
  .searchArtistsAutocompleteLabel > .artistsAutocomplete {
    width: 308px;
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .dialog {
    z-index: $searchZIndexInMobile !important;
    .search {
      .filtersContainer {
        display: none;
      }
      .searchHeader {
        align-items: unset;
        flex-direction: column;
        gap: 40px;
        .searchDoubleBtn {
          align-self: flex-end;
        }
        .searchQuery {
          &::placeholder {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
