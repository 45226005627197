@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute;
  z-index: $popupsZIndex;
  .dialogPaper {
    background-color: $white;
    border: 1px solid $grayLight;
    border-radius: 6px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    max-height: unset;
    padding: 30px;
    position: fixed;
  }
  .dialogBackdrop {
    background-color: transparent;
  }
  .dialogHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
    svg {
      cursor: pointer;
      height: 13px;
      stroke: black;
      stroke-width: 2px;
      width: 13px;
    }
  }
  .inputsContainer {
    column-gap: 15px;
    display: flex;
  }
  .withMarginBottom {
    margin-bottom: 16px;
  }
  .toggleBtnFormRoot {
    margin-bottom: 16px;
    margin-top: 4px;
  }
  .holdRequestFormMessage {
    margin-bottom: 30px;
  }
  .contactTitle,
  .holdRequestFormMessageTitle {
    color: $gray;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
  }
  .holdRequestFormMessageTitle {
    margin-bottom: 10px;
  }
  .holdRequestButtons {
    justify-content: flex-end;
  }
}

.optionContainer {
  display: flex;
  .titleAndTeamWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .optionInitials {
    align-items: center;
    background-color: $grayDark;
    border-radius: 50%;
    color: $white;
    display: flex;
    height: 26px;
    justify-content: center;
    margin-right: 10px;
    width: 26px;
    &.optionTeamInitials {
      background-color: $WCMAccent;
    }
  }
  span {
    display: block;
  }
  &.selectedOption {
    span {
      color: $WCMAccent;
    }
  }
  &:hover {
    span {
      color: $WCMAccent;
    }
  }
}
