@import 'src/styles/colors';

.systemPitchFormContent {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  width: 550px;
  :global {
    .MuiDialogContent-root {
      max-height: 450px;
    }
  }
  .textEditor {
    height: 110px;
    min-height: 110px;
    width: 100%;
    :global {
      .ql-container {
        height: 80px;
        min-height: 80px;
      }
    }
  }
  .textEditorLabel {
    color: $gray;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    margin-bottom: 8px;
  }
  .pitchFormSwitchBtn {
    align-items: flex-start !important;
    height: auto;
    justify-content: flex-end;
    :global {
      .MuiFormControlLabel-label {
        color: $gray !important;
        font-family: SuisseIntl-SemiBold-M, sans-serif !important;
        letter-spacing: normal;
      }
      .MuiSwitch-root {
        margin-top: 6px;
      }
      .MuiButtonBase-root {
        padding-bottom: 0;
      }
    }
  }
}

.row {
  align-items: center;
  border-bottom: 1px solid $grayLight;
  display: flex;
  height: 55px;
  width: 100%;
}

.playCell {
  flex-shrink: 0;
  text-align: center;
  width: 48px;
}

.titleCell {
  flex-grow: 1;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  .title {
    display: inline-block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .description {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.versionCell {
  flex-shrink: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 85px;
}

.actionsCell {
  flex-shrink: 0;
  text-align: center;
  width: 48px;
}

.message {
  margin: 20px 0;
  text-align: center;
}

.heading {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
}

.labelText {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  margin-bottom: 15px;
}

.value {
  margin-bottom: 5px;
}

.pitchedTo {
  margin-bottom: 5px;
}

.tag {
  background-color: $WCMAccent;
  border-radius: 10px;
  color: white;
  display: inline-block;
  font-size: 11px;
  height: 20px;
  line-height: 20px;
  margin-right: 15px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  .tagInner {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
