@import 'src/styles/vars';
@import 'src/styles/colors';
@import 'src/styles/mixins';

//animations for Like
@include keyframe(stroke-animation) {
  0% {
    stroke-dashoffset: 0;
  }
  30% {
    stroke-dashoffset: 47;
  }
  60% {
    fill: transparent;
    stroke-dashoffset: 94;
    transform: scale(1);
  }
  90% {
    fill: $WCMAccent;
    transform: scale(1.2);
  }
  100% {
    fill: $WCMAccent;
    stroke-dashoffset: 94;
    transform: scale(1);
  }
}

@include keyframe(fade-animation) {
  60% {
    fill: transparent;
    transform: scale(1);
  }
  70% {
    fill: $WCMAccent;
    opacity: 1;
    transform: scale(1.1);
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

//animation for Dislike

@include keyframe(dislike-animation) {
  0% {
    fill: $WCMAccent;
    transform: rotateY(0);
  }
  70% {
    fill: transparent;
  }
  100% {
    fill: transparent;
    transform: rotateY(180deg);
  }
}

@include keyframe(fade-open) {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@include keyframe(fade-close) {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.musicPlayer {
  .swipeDownBtn {
    cursor: pointer;
    left: 0;
    margin: 0 auto;
    padding: 13px 50px 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 171px;
    div {
      background-color: $grayLight;
      border-radius: 5px;
      height: 5px;
      opacity: 0;
      transition: opacity 0.1s 0s;
      width: 71px;
    }
  }
  .favoriteSnackbarContainer,
  .duplicateSnackbarContainer {
    bottom: calc(100% + 20px);
    position: absolute;
    .favoriteSnackbarContent,
    .duplicateSnackbarContent {
      background-color: $white;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      justify-content: center;
      min-width: unset;
    }
  }
  .favoriteSnackbarContainer .favoriteSnackbar,
  .favoriteSnackbarContainer .favoriteSnackbar button,
  .duplicateSnackbarContainer span {
    color: $grayDark;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 11px;
    line-height: 13px;
  }
  .duplicateSnackbarContainer {
    .duplicateSnackbarContent {
      padding: 20px 14px 14px 6px;
      width: 233px;
      & > div {
        padding: 0;
      }
    }
    h1 {
      color: $grayDark;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 12px;
      margin-left: 15px;
    }
    span {
      display: block;
      margin-bottom: 14px;
      margin-left: 15px;
    }
  }
  .favoriteSnackbarContainer {
    .favoriteSnackbar {
      white-space: nowrap;
    }
    .favoriteSnackbarContent {
      padding: 12px 21px;
      & > div {
        padding: 0;
      }
    }
    .favoriteSnackbar button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .activeIcon {
    path {
      fill: $WCMAccent !important;
    }
  }
  .disabledIcon {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
  .hidden {
    visibility: hidden;
  }
  .title {
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 11px;
    line-height: 13px;
  }
  .plusContainer,
  .volumeContainer,
  .moreContainer,
  .markerContainer {
    background-color: $white;
    bottom: 40px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    position: absolute;
    transform: translateX(50%);
    z-index: $playBarPopupZIndex;
    &:after {
      border-left: solid 9px transparent;
      border-right: solid 9px transparent;
      border-top: solid 9px white;
      content: '';
      height: 0;
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translateX(-50%);
      width: 0;
    }
  }
  .markerContainer {
    bottom: 34px;
    padding: 18px 30px;
    right: 7px;
    text-align: left;
    .markerOption {
      color: $grayDarker;
      cursor: pointer;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 20px;
      white-space: nowrap;
      &:hover {
        color: #ba9803;
      }
    }
    .exitBtn {
      color: $gray;
    }
  }
  .plusContainer {
    max-width: 226px;
    padding: 28px 22px 34px 22px;
    right: 85px;

    &.plusContainerAdditional {
      right: 101px;

      &:after {
        left: 57%;
      }
    }

    .addToPlaylist {
      color: $grayDark;
      display: inline-block;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 25px;
      margin-left: 9px;
    }
    .recentPlaylistsContainer {
      margin-top: 26px;
      .recentPlaylistsTitle,
      .recentPlaylists {
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 13px;
        line-height: 23px;
      }
      .recentPlaylistsTitle {
        color: $gray;
      }
      .recentPlaylists {
        color: $grayDarker;
        cursor: pointer;
        display: block;
        max-width: 182px;
        width: fit-content;
        &:hover {
          color: $WCMAccent;
        }
      }
    }
  }
  .volumeContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 146px;
    padding: 23px 17px 4px;
    right: 46px;
    width: 34px;
    svg {
      cursor: pointer;
      height: 24px;
      margin-top: 5px;
      width: 12px;
      path {
        fill: $grayLight;
      }
    }
    .root {
      height: 100px;
      transition: height $transition_timing;
      .rail {
        background-color: $grayDark;
      }
      .track {
        background-color: $WCMAccent;
      }
      .thumb {
        background: $grayDarker;
        box-shadow: 0 0 0 5px #f0f0f0 !important;
        &:after {
          bottom: -6px;
          left: -6px;
          right: -6px;
          top: -6px;
        }
      }
    }
  }
  .expandActionBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: none;
    outline: none;
    position: relative;
    svg {
      height: 25px;
      overflow: visible;
      transform: scaleY(-1);
      vertical-align: middle;
      width: 25px;
      path {
        fill: $grayDarker;
      }
    }
    .activeIcon {
      path {
        stroke: $WCMAccent;
      }
    }
  }
  .moreContainer {
    padding: 24px 24px 30px 24px;
    right: 7px;
    &.moreContainerAdditional {
      right: 81px;
      &:after {
        left: calc(100% - 18px);
      }
    }
    .moreItem {
      color: $grayDarker;
      cursor: pointer;
      font-family: SuisseIntl-Regular, sans-serif;
      font-size: 13px;
      line-height: 24px;
      &.disabledItem {
        color: $grayLight;
        pointer-events: none;
      }
      &:hover {
        color: $WCMAccent;
      }
    }
    .label {
      align-items: center;
      display: flex;
      justify-content: center;
      svg {
        margin-left: 6px;
        transform: scale(1.4);
      }
    }
  }
  .playBarHeader {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    .titleContainer,
    .playControlsContainer,
    .actionsContainer {
      flex: 1;
      .heartBackground {
        path {
          transform-origin: center;
        }
      }
      .arrowDownIcon {
        display: none;
        margin-left: 20px;
        & > path {
          fill: $grayDarker;
        }
      }
      .moreIcon {
        path {
          fill: black;
        }
      }
    }
    .heartContainer {
      cursor: pointer;
      display: flex;
      position: relative;
      svg {
        height: 16px;
        overflow: visible;
        width: 18px;
      }
      .heartBackground {
        fill: #e3ce8b;
        position: absolute;
        path {
          stroke: none;
        }
      }
      .heartIcon {
        fill: white;
        position: relative;
        z-index: 2;
        path {
          stroke-dasharray: 47;
          stroke-dashoffset: 0;
          transform-origin: center;
        }
      }
      &.favorite .heartIcon {
        fill: $WCMAccent;
      }
      &.like {
        .heartIcon path {
          @include animation('stroke-animation .9s ease-in-out forwards');
        }
        .heartBackground path {
          @include animation('fade-animation .9s ease-in-out forwards');
        }
      }
      &.dislike {
        .heartIcon path {
          @include animation('dislike-animation .4s ease-in-out forwards');
        }
        .heartBackground path {
          @include animation('dislike-animation .4s ease-in-out forwards');
        }
      }
    }
    .playControlsContainer,
    .actionsContainer {
      align-items: center;
      display: flex;
      & > svg {
        cursor: pointer;
        margin-left: 26px;
      }
      .controlBtn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        margin: 0 13px;
        outline: none;
        position: relative;
        svg {
          vertical-align: middle;
        }
        .activeIcon {
          path {
            stroke: $WCMAccent;
          }
        }
      }
    }
    .playControlsContainer {
      justify-content: center;
      .heartContainer {
        display: none;
      }
    }
    .actionsContainer {
      justify-content: flex-end;
      position: relative;
    }
    .titleContainer {
      display: flex;
      .titleTextWrapper {
        margin-left: 13px;
        span {
          font-family: SuisseIntl-SemiBold-M, sans-serif;
          font-size: 13px;
          height: 16px;
          line-height: 16px;
        }
        .title {
          margin-bottom: 3px;
        }
        .writers {
          color: $grayDark;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  .playBarWaves {
    align-items: flex-end;
    display: flex;
    .timeInfo {
      color: $grayDarker;
      font-family: SuisseIntl-Regular, sans-serif;
      font-size: 10px;
      line-height: 12px;
      width: 25px;
      &.timeInfoStart {
        margin-right: 14px;
      }
      &.timeInfoEnd {
        margin-left: 14px;
        white-space: nowrap;
      }
    }
    .waveForm {
      height: 36px;
      width: 100%;
    }
  }
  &.darkMusicPlayer {
    background-color: #000000;
    .playBarHeader {
      .expandActionBtn {
        svg path {
          fill: $grayLight;
        }
      }
      .playControlsContainer {
        .nextPrevIcon {
          path {
            fill: $grayLight !important;
          }
        }
      }
    }
    .volumeContainer {
      right: 7px;
    }
    .actionsContainer {
      path {
        fill: $grayLight !important;
      }
    }
    .playBarWaves {
      .timeInfo {
        color: $grayLight;
      }
    }
  }
  &.externalMusicPlayer {
    .playBarHeader {
      .titleContainer {
        margin-left: 22px;
      }
      .heartContainer {
        display: none;
      }
    }
  }
}

.markersContainer {
  align-items: center;
  display: flex;
  height: 31px;
  padding: 4px 39px;
  .markerItem {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 25px;
    justify-content: space-between;
    position: absolute;
    transform: translateX(-50%);
    .markerTitleContainer {
      align-items: center;
      background-color: $grayDarker;
      border: 1px solid $white;
      color: $white;
      display: none;
      padding: 3px 6px;
      transform: translateY(10px);
      &:hover {
        display: flex;
      }
      .markerTitle {
        font-family: SuisseIntl-Regular-M, sans-serif;
        font-size: 10px;
        line-height: 11px;
      }
      .deleteIcon {
        cursor: pointer;
        margin-left: 3px;
      }
    }
    &.disabledMarker {
      pointer-events: none;
      .markerTitleContainer {
        display: flex;
        .deleteIcon {
          display: none;
        }
      }
    }
    &:not(.disabledMarker) {
      z-index: $markerItemZIndex;
    }
    &:hover {
      z-index: $markerItemHoverZIndex;
    }
    .markerCircle {
      background-color: $WCMAccent;
      border-radius: 3px;
      cursor: ew-resize;
      height: 6px;
      width: 6px;
      &:before {
        content: '';
        height: 10px;
        left: 0;
        position: absolute;
        top: 6px;
        width: 100%;
      }
      &:hover {
        & ~ .markerTitleContainer {
          display: flex;
        }
      }
    }
  }
  &.disableCursor {
    .markerCircle,
    .markerTitle {
      cursor: default;
    }
  }
}

.markersContainerMobile {
  background-color: $WCMAccent;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  bottom: 0;
  height: 45px;
  left: 1.5px;
  padding: 0 33px;
  position: fixed;
  transition: height 0.3s ease, bottom 0.3s ease;
  width: calc(100% - 3px);
  .markersHeaderMobile {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    transition: padding-top 0.3s ease;
    .titleContainerMobile {
      align-items: center;
      display: flex;
      .countMobile {
        background-color: $white;
        border: 1px solid $grayLight;
        border-radius: 10px;
        margin-left: 10px;
        padding: 3px 9px 2px;
      }
    }
  }
  .viewBtn {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    height: 23px;
    padding: 0 10px;
    .viewBtnText {
      margin-right: 10px;
    }
    svg {
      height: 9px;
      width: 9px;
      path {
        color: $white;
      }
    }
  }
  .markersListMobile {
    max-height: calc(100% - 100px);
    overflow: auto;
    padding: 0 29px 0 1px;
    .markerItemMobile {
      align-items: center;
      display: flex;
      .markerPlayIconMobile {
        cursor: pointer;
        height: 11px;
        width: 11px;
        path {
          fill: $white;
        }
      }
      .markerTitleMobile {
        margin-left: 6px;
        margin-right: auto;
        max-width: calc(100% - 70px);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
  &.viewModeOn {
    border-radius: 0;
    bottom: 117px;
    height: calc(100% - 117px);
    left: 0;
    width: 100%;
    .markersHeaderMobile {
      margin-bottom: 29px;
      margin-top: 32px;
    }
  }
}

.musicPlayer.expanded ~ .markersContainerMobile {
  &.viewModeOn {
    bottom: 226px;
    height: calc(100% - 226px);
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .musicPlayer {
    background-color: $white;
    padding: 21px 0 0 0;
    transition: padding-top 0.2s ease-out, margin-bottom 0.3s ease-out;
    z-index: 1;
    @include animation('fade-close .3s ease-in-out');
    .playBarHeader {
      flex-direction: row-reverse;
      margin-bottom: 14px;
      padding: 0 30px;
      position: relative;
      .titleContainer {
        left: 32px;
        .heartContainer {
          display: none;
        }
        .titleTextWrapper {
          margin-left: 17px;
        }
      }
      .playControlsContainer {
        flex: unset;
        .heartContainer {
          margin: 0 13px;
        }
        .playPauseIcon {
          height: 35px;
          width: 31px;
        }
        .nextPrevIcon {
          height: 22px;
          width: 30px;
        }
        .expandedBtn {
          display: none;
        }
      }
      .actionsContainer {
        flex: unset;
        height: 29px;
        right: 32px;
        svg {
          height: 20px;
          width: 25px;
        }
        .moreIcon {
          display: none;
          transform: rotate(90deg);
          width: 16px;
        }
      }
      .expandActionBtn {
        display: flex;
      }
      &.expanded {
        margin-bottom: 30px;
        .titleContainer,
        .actionsContainer {
          bottom: calc(100% + 31px);
          position: absolute;
          .moreIcon,
          .arrowDownIcon {
            display: block;
          }
        }
        .titleContainer {
          .titleTextWrapper {
            margin-left: 0;
          }
        }
        .expandActionBtn {
          display: none;
        }
        .playControlsContainer {
          flex: 1;
          .expandedBtn,
          .heartContainer {
            display: flex;
          }
        }
      }
    }
    .playBarWaves {
      position: relative;
      .timeInfo {
        display: none;
      }
      &.expanded .timeInfo {
        bottom: calc(100% + 7px);
        display: block;
        position: absolute;
        &.timeInfoStart {
          left: 9px;
        }
        &.timeInfoEnd {
          right: 9px;
        }
      }
      .waveForm {
        height: 47px;
      }
    }

    .desktopIcon {
      display: none !important;
    }
    &.expanded {
      @include animation('fade-open .3s ease-in-out');
      padding: 114px 0 0 0;
      .swipeDownBtn {
        display: block;
        pointer-events: all;
        div {
          opacity: 1;
          transition: opacity 0.3s 0.1s;
        }
      }
    }
    &.withMarginBottom {
      margin-bottom: 53px;
    }
  }
}

@media (min-width: $breakpoint_tablet) and (max-width: $breakpoint_desktop-small) {
  .musicPlayer {
    .actionsContainer {
      .moreContainer {
        right: 81px;
        &:after {
          left: 90%;
        }
      }
      .plusContainer {
        right: 101px;
        &:after {
          left: 57%;
        }
      }
    }
  }
}

@media (min-width: $breakpoint_tablet) {
  .musicPlayer {
    .playBarHeader {
      .titleContainer {
        max-width: 30%;
        .titleTextWrapper {
          max-width: calc(100% - 31px);
        }
      }
    }
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .musicPlayer {
    .playBarHeader {
      .titleContainer {
        width: calc(100% - 117px);
        .titleTextWrapper {
          max-width: calc(100% - 50px);
        }
      }
    }
  }
}
