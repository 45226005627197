@import 'src/styles/colors';
@import 'src/styles/vars';

.search {
  background-color: $wipAreaBck;
  border-radius: 50px;
  display: flex;
  padding: 11px 16px;
  width: 182px;
  input {
    background-color: transparent;
    border: none;
    color: $grayDarker;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    line-height: 15px;
    margin-left: 7px;
    outline: none;
    width: 100%;
    &::placeholder {
      color: $grayDark;
    }
  }
}
