@import 'src/styles/colors';

.contactOption {
  .email {
    color: $grayDarker;
    font-size: 11px;
  }
  .team {
    color: $gray;
    font-size: 11px;
    margin-top: 3px;
  }
}
