@import 'src/styles/colors';
@import 'src/styles/vars';

.autocomplete {
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
  fieldset {
    border: 1px solid $grayLight !important;
    border-radius: 0;
  }
  input {
    box-sizing: border-box;
    font-family: SuisseIntl-Regular-M, sans-serif;
    font-size: 13px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 3px;
    margin-top: 3px;
    padding: 3px !important;
    &::placeholder {
      color: $gray;
      font-style: italic;
      letter-spacing: normal;
      opacity: 1;
    }
  }
  .chipRoot {
    background-color: $WCMAccent;
    color: white;
    font-size: 11px;
    height: 20px;
    line-height: 20px;
    svg {
      color: white;
      height: 10px;
      margin-right: 10px;
      min-width: 10px;
      width: 10px;
    }
  }
  :global {
    .MuiInputBase-root {
      background-color: white;
      min-height: 35px;
      padding: 4px 35px 4px 12px !important;
    }
    .MuiAutocomplete-endAdornment {
      position: static;
    }
    .MuiAutocomplete-popupIndicatorOpen {
      transform: none;
    }
    .Mui-error fieldset {
      border-bottom-color: $danger !important;
    }
    .Mui-focused fieldset {
      border-bottom-color: black !important;
    }
    .Mui-disabled {
      background-color: $grayLighter;
    }
    .MuiSvgIcon-root {
      height: 16px;
    }
  }
}

.requiredAutocomplete {
  :global {
    .MuiInputBase-root {
      padding-right: 50px !important;
    }
    .MuiAutocomplete-clearIndicator {
      position: absolute;
      top: 50%;
      transform: translate(-180%, -50%);
    }
  }
}

.clearableSelect {
  input {
    margin-right: 20px;
  }
}

.hasRequiredOrErrorIcon {
  :global {
    .MuiInputBase-root {
      padding-right: 50px !important;
    }
  }
}

.paper {
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  margin: 0 !important;
  .separator {
    border-bottom: 0;
    border-color: $grayLighter;
    border-style: solid;
    margin: 0;
  }
  .addNewButton {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $WCMAccent;
    cursor: pointer;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    min-height: 37px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    &:focus,
    &:hover {
      color: $WCMAccentHover;
    }
  }
  :global {
    .MuiAutocomplete-listbox {
      margin: 8px;
      padding: 0;
    }
    .MuiAutocomplete-option {
      padding-left: 8px;
      padding-right: 8px;
      &[aria-selected='true'],
      &[data-focus='true'] {
        background-color: transparent;
        color: $WCMAccent;
      }
    }
  }
}

.label {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif !important;
  font-size: 13px !important;
  letter-spacing: normal !important;
  line-height: 16px !important;
  &:empty {
    display: none;
  }
}

.labelPlacementStart {
  margin-left: 0 !important;
  margin-right: 0 !important;
  :global {
    .MuiFormControlLabel-label {
      width: 100px;
    }
  }
}

.labelPlacementTop {
  align-items: flex-start !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  :global {
    .MuiFormControlLabel-label {
      margin-bottom: 8px;
    }
    .MuiAutocomplete-popperDisablePortal {
      top: calc(100% - 5px);
    }
  }
}

.tooltipPopper {
  z-index: $tooltipPopperZIndex !important;
}

.tooltip {
  background-color: $danger !important;
  border-radius: 0 !important;
  color: white !important;
  font-family: SuisseIntl-Regular-M, sans-serif !important;
  font-size: 11px !important;
  line-height: 13px !important;
  min-width: 120px;
  padding: 10px !important;
}

.tooltipArrow {
  color: $danger !important;
}

.requiredIcon {
  &::after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
  }
}

.icon {
  align-items: center;
  display: flex;
  height: 32px;
  margin-right: 9px;
}

.icons {
  align-items: center;
  color: currentColor;
  display: flex;
  font-size: 11px;
  line-height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.errorBorder {
  border-bottom-color: $danger;
}

.errorColor {
  color: $danger;
  path {
    fill: $danger;
  }
}

.validBorder {
  fieldset {
    border-bottom-color: $success !important;
  }
}

.validColor {
  color: $success;
}
