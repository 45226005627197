@import 'src/styles/vars';
@import 'src/styles/colors';

.container {
  background-color: white;
  color: $grayDarker;
  display: flex;
  font-family: "Suisse Int'l", sans-serif;
  font-size: 13px;
  line-height: 15px;
}

.inputPanel {
  display: flex;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.inputContainer {
  min-width: 50%;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}

.label {
  display: block;
  font-weight: 600;
  line-height: 16px;
  margin: 32px 0 4px 0;
  padding-bottom: 10px;
  padding-top: 10px;
}

.input {
  border: 1px solid $grayLight;
  display: block;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  height: 35px;
  outline: none;
  padding: 0 14px;
  width: 100%;

  &:hover {
    border-color: black;
  }

  &:focus {
    border-color: $WCMAccent;
  }
}

.controller {
  min-height: 313px; // To prevent DOM jumping

  // Override react-dates default styles
  :global {
    .DayPicker__horizontal,
    .DayPicker,
    .CalendarMonth,
    .CalendarMonthGrid,
    .CalendarDay__default {
      background: none;
    }

    .CalendarMonth {
      padding: 0 12px !important;
    }

    .CalendarMonth_caption {
      font-size: 16px;
      padding-bottom: 55px;
    }

    .DayPicker {
      min-height: 318px;
    }

    .DayPicker_weekHeader_li {
      color: black;
      font-weight: 600;

      small {
        font-size: 14px;
        font-weight: normal;
      }
    }

    .CalendarDay {
      border: none;
      color: $grayDarker;
      font-size: 11px;
      font-weight: 600;
      vertical-align: middle;
    }

    .CalendarDay__blocked_calendar {
      color: $grayLight;
    }

    .CalendarDay__selected_span {
      background-color: $wipAreaBck;
    }

    .CalendarDay__hovered_span,
    .CalendarDay__selected_span:hover {
      background-color: $grayLighter;
    }

    .CalendarDay__selected {
      background-color: $WCMAccent;
      color: white;
    }
  }
}

.controller.singleDateController {
  min-height: 304px; // To prevent DOM jumping
  padding-bottom: 25px;
  padding-top: 10px;
  :global {
    .DayPicker {
      min-height: 309px;
    }
  }
  .doubleBGroup {
    justify-content: center;
    position: relative;
    top: -10px;
  }
}

.navButton {
  background-color: transparent;
  border: none;
  color: $gray;
  cursor: pointer;
  padding: 8px;
  position: absolute;
  top: 11px;

  &:focus,
  &:hover {
    color: $grayDarker;
  }
}

.navNextButton {
  right: 10px;
}

.navPrevButton {
  left: 10px;
}

.navButtonIcon {
  height: 20px;
  width: 20px;
}

.toolbar {
  margin-left: 48px;
  padding-right: 20px;
  padding-top: 75px;
  &.singleDateToolbar {
    padding-top: 25px;
    .doubleBGroup {
      right: 0;
      button {
        width: 130px;
      }
    }
  }
  .doubleBGroup {
    position: relative;
    right: 15px;
  }
}

.toolbarIsOpenSidebar:not(.singleDateToolbar) {
  display: none;
}

.singleDateToolbarButtonWithActions {
  display: block !important;
}

.filterButton:not(:last-of-type) {
  margin-bottom: 30px;
}

.filterButton:last-of-type {
  margin-bottom: 52px;
}

.toolbarButton {
  display: none;
  margin: 0 auto 38px auto;
  &.singleDateToolbarButton {
    display: block;
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .controller {
    min-height: 304px; // To prevent DOM jumping
    :global {
      .DayPicker {
        min-height: 309px;
      }
    }
  }
  .toolbar:not(.singleDateToolbar) {
    display: none;
  }
  .toolbarButton {
    display: block;
  }
}
