@import 'src/styles/colors';
@import 'src/styles/vars';

.externalWrapper {
  background-color: $DarkBck;
  height: calc(100vh - 190px);
  overflow-y: auto;
  width: 100vw;
  &::after {
    background-color: $DarkBck;
    content: '';
    height: 100%;
    position: absolute;
    width: 100%;
  }
  &.externalWrapperExpandedPlaybar {
    height: calc(100vh - 300px);
  }
}

.externalContainer {
  height: 100%;
  margin: 0 auto;
  max-width: 1100px;
  padding-top: 32px;
}

.externalHeader {
  margin-bottom: 56px;
}

.titleContainer {
  align-items: center;
  display: flex;
  margin-bottom: 38px;
  padding: 0 10px;
}

.pitchTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.externalCount {
  background-color: $grayLighter;
  border-radius: 12px;
  color: #000000;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  line-height: 13px;
  margin: 0 40px 0 10px;
  padding: 2px 10px;
}

.byInfo {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-left: auto;
  overflow: hidden;
  word-break: break-all;
}

.expiredInfoContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 120px;
  svg {
    margin-bottom: 10px;
  }
  .expiredInfoText {
    line-height: 24px;
    text-align: center;
    white-space: pre-line;
  }
}

.dataGrid {
  height: calc(100% - 184px);
  & > div {
    background-color: $DarkBck !important;
  }
  :global {
    .ag-header {
      background-color: $DarkTableHeader !important;
      .ag-header-cell {
        background-color: $DarkTableHeader !important;
      }
    }
    .ag-row {
      background-color: $DarkBck;
    }
    .ag-overlay-no-rows-center {
      color: $white;
    }
  }
  .headerCell {
    color: $gray !important;
  }
  .writersText {
    max-width: fit-content;
  }
  .row {
    border-color: $grayDarker;
    &:hover {
      background-color: #1f1f1f !important;
    }
  }
  svg {
    cursor: pointer;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    width: 16px;

    path {
      fill: $WCMAccent;
    }
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .dataGrid {
    height: calc(100% - 220px);
  }
  .externalHeader {
    margin-bottom: 20px;
  }
  .titleContainer {
    flex-wrap: wrap;
    margin-bottom: 16px;
    .pitchTitle {
      width: calc(100% - 58px);
    }
    .externalCount {
      margin-right: 0;
    }
    .byInfo {
      -webkit-line-clamp: 2;
      margin-left: 0;
      margin-top: 10px;
    }
  }
  .externalWrapperExpandedPlaybar .dataGrid {
    :global {
      .ag-root-wrapper {
        min-height: 150px !important;
      }
    }
  }
}
