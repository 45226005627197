@import 'src/styles/vars';
@import 'src/styles/colors';

.addToPlaylistContainer {
  background-color: $white;
  max-width: 226px;
  padding: 28px 22px 34px 22px;
  z-index: $playBarPopupZIndex;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .addToPlaylist {
    color: $grayDark;
    display: inline-block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 25px;
    margin-left: 9px;
  }
  .recentPlaylistsContainer {
    margin-top: 26px;
    .recentPlaylistsTitle,
    .recentPlaylists {
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 23px;
    }
    .recentPlaylistsTitle {
      color: $gray;
    }
    .recentPlaylists {
      color: $grayDarker;
      cursor: pointer;
      display: block;
      max-width: 182px;
      width: fit-content;
      &:hover {
        color: $WCMAccent;
      }
    }
  }
}

.addToPlaylistPopper {
  pointer-events: all !important;
  .addToPlaylistTooltip {
    background-color: $white;
    border-radius: 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 10px 0;
    max-width: unset;
    padding: 0;
  }
  .addToPlaylistArrow {
    color: $white;
    font-size: 20px;
    &:before {
      border: 1px solid $grayLight;
    }
  }
}
