@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute !important;
  top: -30px !important;
  z-index: $popupsZIndex !important;
  .dialogPaper {
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    max-height: unset;
    max-width: unset;
    padding: 55px 52px 52px 52px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }
  .dialogBackdrop {
    background-color: transparent;
  }
  .dialogHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 54px;
    span {
      color: $grayDarker;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
    }
    svg {
      cursor: pointer;
    }
  }
}

@media (max-width: $breakpoint_tablet - 1) {
  .dialog {
    display: none;
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .dialog {
    .dialogPaper {
      max-height: unset;
      padding: 48px 52px 48px 45px;
      .dialogHeader {
        margin-bottom: 40px;
        padding-left: 3px;
      }
    }
  }
}

@media (min-width: $breakpoint_desktop-small) and (max-width: $breakpoint_desktop-big - 1) {
  .dialog.openSidebar {
    .dialogPaper {
      max-height: unset;
      padding: 48px 52px 48px 45px;
      .dialogHeader {
        margin-bottom: 40px;
        padding-left: 3px;
      }
    }
  }
}
