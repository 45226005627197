@import 'src/styles/vars';

.logoContainer {
  align-items: center;
  cursor: default;
  display: flex;
  justify-content: center;
  position: relative;
  user-select: none;
  z-index: $logoZIndex;
  &.pointer {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  .headerTitle {
    color: #000000;
    font-family: Plaak, sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
    margin-left: 18px;
  }
}
