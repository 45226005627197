@import 'src/styles/colors';
@import 'src/styles/vars';

.filtersForm {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  padding-right: 4px;
  .filtersContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 718px;
    label {
      margin-bottom: 10px;
      margin-right: 0;
      width: 332px;
      &:last-of-type {
        margin-bottom: 0;
      }
      & > input {
        height: 32px;
        width: 230px;
      }
      .autocompleteRoot {
        background-color: $white;
        border: 1px solid $grayLight;
        margin-left: 15px;
        transition: width $transition_timing, height $transition_timing !important;
        width: 230px;
        .autocompleteEndAdornment {
          background-color: red;
        }
      }
    }
    .percentageIcon {
      height: 9px;
      width: 9px;
    }
    .writersAutocompleteLabel {
      justify-content: space-between;
      margin-left: 0;
      .writersAutocomplete {
        width: 230px;
        .writersInputBaseClassName {
          min-height: 33px;
          input {
            &::placeholder {
              color: $grayLight;
            }
          }
          fieldset {
            border-color: $grayLight !important;
          }
        }
      }
      & > span {
        color: #383838;
        font-family: SuisseIntl-Regular-M, sans-serif !important;
      }
    }
  }
  .doubleBtnGroupAdditional {
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.autocompletePopper {
  top: 1px !important;
}

.autocompletePaper {
  border-radius: unset !important;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3) !important;
  margin: 0 !important;
  padding: 25px 7px 25px 22px;
  top: 20px;
  width: 228px;
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .filtersForm {
    .filtersContainer {
      width: 332px;
      label:last-of-type {
        margin-bottom: 40px;
      }
    }
    .doubleBtnGroupAdditional {
      position: static;
    }
  }
}

@media (min-width: $breakpoint_desktop-small) and (max-width: $breakpoint_desktop-big - 1) {
  .filtersForm.openSidebar {
    .filtersContainer {
      width: 332px;
      label:last-of-type {
        margin-bottom: 40px;
      }
    }
    .doubleBtnGroupAdditional {
      position: static;
    }
  }
}
