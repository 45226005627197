@import 'src/styles/vars';
@import 'src/styles/colors';

.notificationsContainer {
  background-color: $white;
  border: 1px solid $grayLight;
  border-radius: 10px;
  max-height: 540px;
  padding-top: 30px;
  width: 336px;
  z-index: $playBarPopupZIndex;
  .seeAllBtn {
    background-color: $wipAreaBck;
    border: none;
    border-radius: 0 0 10px 10px;
    cursor: pointer;
    padding: 17px 0;
    text-transform: uppercase;
    width: 100%;
  }
}

.notificationsPopper {
  pointer-events: all !important;
  .notificationsTooltip {
    background-color: $white;
    border-radius: 10px;
    margin: 10px 0;
    max-width: unset;
    padding: 0;
  }
}
