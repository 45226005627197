@import 'src/styles/colors';

.statusContainer {
  align-items: center;
  border-radius: 20px;
  color: $white;
  display: flex;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 11px;
  height: 23px;
  justify-content: center;
  line-height: 13px;
  position: relative;
  width: 76px;
  &.active,
  &.approved {
    background-color: $WCMAccent;
  }
  &.rejected,
  &.denied {
    background-color: $danger;
  }
  &.pending {
    background-color: $grayDarker;
  }
  &.cancelled,
  &.canceled,
  &.archived,
  &.deactive {
    background-color: $grayLighter;
  }
}
