@import 'src/styles/colors';

.manualPitchFormContent {
  color: $grayDarker;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  overflow: hidden;
  width: 550px;
  :global {
    .MuiDialogContent-root {
      max-height: 450px;
    }
  }
}

.pitchedTo {
  margin-bottom: 5px;
}

.searchbox {
  margin-bottom: 15px;
}

.row {
  align-items: center;
  border-bottom: 1px solid $grayLight;
  display: flex;
  height: 55px;
  width: 100%;
}

.playCell {
  flex-shrink: 0;
  text-align: center;
  width: 48px;
}

.titleCell {
  flex-grow: 1;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  .title {
    display: inline-block;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .description {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.versionCell {
  flex-shrink: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 85px;
}

.actionsCell {
  flex-shrink: 0;
  text-align: center;
  width: 48px;
}

.resultEditableCell {
  flex-shrink: 0;
  width: 135px;
}

.resultCell {
  flex-shrink: 0;
  padding-left: 15px;
  padding-right: 15px;
  width: 90px;
}

.addSongs {
  min-height: 152px;
}

.searchSongsContainer {
  max-height: 220px;
}

.addedSongsContainer {
  max-height: 110px;
}

.addSongButton {
  path {
    fill: $WCMAccent;
  }
  &:focus,
  &:hover {
    path {
      fill: $WCMAccentHover;
    }
  }
}

.message {
  margin: 20px 0;
  text-align: center;
}

.heading {
  color: $grayDarker;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-top: 20px;
}

.overflowY {
  overflow-y: auto;
}

.labelText {
  color: $gray;
  font-family: SuisseIntl-SemiBold-M, sans-serif;
  margin-bottom: 15px;
}

.value {
  margin-bottom: 5px;
}
